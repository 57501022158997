import { useState } from 'react';
import { Button, Modal } from 'antd';
import { mapsPostAPI } from 'api/maps';
import { useTenants } from 'hooks/useTenants/useTenants';
import {
  AreaIsOpenSwitchForm,
  AreaNameTextAreaForm,
  IsFacilitySwitchForm,
  MapDescriptionTextAreaForm,
  ParentMapForm,
  TenantSelectForm,
} from './commons';
import { useParentMaps } from 'hooks/useParentMaps/useParentMaps';

type MapCreateDialogProps = {
  reload(): void;
};

// -- main component --
/**
 * マップ新規追加ダイアログです。
 */

const MapCreateDialog = (params: MapCreateDialogProps) => {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [map_name, setMapName] = useState<string>('');
  const { tenants } = useTenants();
  const { parent_maps } = useParentMaps();
  const [tenant_id, setTenantId] = useState<number>();
  const [is_open, setIsOpen] = useState<boolean>(false);
  const [is_facility, setIsFacility] = useState<boolean>(false);
  const [parent_map_id, setParentMapId] = useState<number | undefined>(undefined);
  const [map_description, setMapDescription] = useState<string>('');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setMapName('');
    setTenantId(undefined);
    setIsOpen(false);
    setIsFacility(false);
    setParentMapId(undefined);
    setMapDescription('');
  };

  const handleOk = async () => {
    if (map_name && tenant_id) {
      const res = await mapsPostAPI({
        map_name: map_name,
        tenant_id: tenant_id,
        map_description: map_description,
        parent_map_id: parent_map_id,
        facility_name: parent_map_id ? map_name : undefined,
        is_facility: is_facility,
        is_open: is_open,
      });
      if (res.status == 200) {
        params.reload();
        resetForm();
        setIsModalOpen(false);
      }
    }
  };

  const handleTenantChange = (new_tenant_id: number) => {
    setTenantId(new_tenant_id);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const onClickIsOpen = () => {
    setIsOpen(!is_open);
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        新規エリア登録
      </Button>
      <Modal
        title='新規エリア追加'
        open={is_modal_open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='登録'
        cancelText='キャンセル'
      >
        <AreaNameTextAreaForm value={map_name} onChange={(value) => setMapName(value.currentTarget.value)} />
        <TenantSelectForm value={tenant_id} onChange={handleTenantChange} tenants={tenants} />
        <MapDescriptionTextAreaForm
          value={map_description}
          onChange={(value) => setMapDescription(value.currentTarget.value)}
        />
        <AreaIsOpenSwitchForm checked={is_open} onClick={onClickIsOpen} />
        <IsFacilitySwitchForm checked={is_facility} onClick={setIsFacility} />
        {is_facility && <ParentMapForm value={parent_map_id} onChange={setParentMapId} parent_maps={parent_maps} />}
      </Modal>
    </>
  );
};

export default MapCreateDialog;
