import CamerasTable from './CameraTable';
import { useState } from 'react';
import { Camera } from 'api/cameras';
import CameraDialog from './CameraDialog';

export default function CamerasPage() {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [select_camera, setSelectCamera] = useState<Camera | undefined>();
  const [is_reload_cameras, setReloadCameras] = useState(false);
  const onModalClose = (update: boolean) => {
    setIsModalOpen(false);
    setSelectCamera(undefined);
    if (update) reloadCameras();
  };

  const reloadCameras = () => {
    setReloadCameras(!is_reload_cameras);
  };

  return (
    <>
      <div style={{ margin: 10 }}>
        <CamerasTable
          onModalOpenChange={setIsModalOpen}
          selectCamera={setSelectCamera}
          is_reload_cameras={is_reload_cameras}
          reloadCameras={reloadCameras}
        />
      </div>
      {select_camera && <CameraDialog camera={select_camera} is_modal_open={is_modal_open} onClose={onModalClose} />}
    </>
  );
}
