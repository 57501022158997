import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja'; // 必要なlocaleファイルをインポート
dayjs.locale('ja');

export function getHourDateRange(current: Dayjs, start_hour?: number, end_hour?: number) {
  const sh = start_hour ?? current.hour();
  const eh = end_hour ?? current.hour() + 1;
  const start = current.set('hour', sh).set('minute', 0);
  const end = current.set('hour', eh).set('minute', 0);
  return { start, end };
}

export function formatHourDate(current: Dayjs, start_hour?: number, end_hour?: number) {
  const { start, end } = getHourDateRange(current, start_hour, end_hour);

  return `${start.format('YYYY/MM/DD HH:mm')} ~ ${end.format('HH:mm')}`;
}

export function formatDayDate(current: Dayjs) {
  return current.format('YYYY/MM/DD');
}

export function formatWeekRangeDate(current: Dayjs) {
  const start_date = dayjs(current).startOf('week');
  const end_date = dayjs(current).endOf('week');
  return `${start_date.format('YYYY/MM/DD (ddd)')} ~ ${end_date.format('YYYY/MM/DD (ddd)')}`;
}

export function formatWeekDate(current: Dayjs) {
  return current.format('MM/DD (ddd)');
}

export function formatMonthDate(current: Dayjs) {
  return current.format('YYYY/MM');
}

export function generateHourUnixTimes(current: Dayjs) {
  const start_date = dayjs(current).startOf('hour');
  const unix_times = [];

  for (let i = 0; i < 13; i++) {
    const date = start_date.add(i * 5, 'minute');
    unix_times.push(date.unix());
  }

  return unix_times;
}

export function generateDayUnixTimes(current: Dayjs) {
  const start_date = dayjs(current).startOf('day');
  const unix_times = [];

  for (let i = 0; i < 25; i++) {
    const date = start_date.add(i, 'hour');
    unix_times.push(date.unix());
  }

  return unix_times;
}

export function generateWeekUnixTimes(current: Dayjs) {
  const start_date = dayjs(current).startOf('week');
  const unix_times = [];

  for (let i = 0; i < 7; i++) {
    const date = start_date.add(i, 'day');
    unix_times.push(date.unix());
  }

  return unix_times;
}

export function generateMonthUnixTimes(current: Dayjs) {
  const start_date = dayjs(current).startOf('month');
  const start_day = start_date.date();
  const end_date = dayjs(current).endOf('month');
  const end_day = end_date.date();
  const unix_times = [];

  for (let i = 0; i < end_day - start_day + 1; i++) {
    const date = start_date.add(i, 'day');
    unix_times.push(date.unix());
  }

  return unix_times;
}
