export interface SaveSvgProps extends React.SVGProps<SVGSVGElement> {
  checked?: boolean;
}
export function SaveSvg(props: SaveSvgProps) {
  return (
    <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.7778 0.5H3.11111C1.38444 0.5 0 1.9 0 3.61111V25.3889C0 27.1 1.38444 28.5 3.11111 28.5H24.8889C26.6 28.5 28 27.1 28 25.3889V6.72222L21.7778 0.5ZM14 25.3889C11.4178 25.3889 9.33333 23.3044 9.33333 20.7222C9.33333 18.14 11.4178 16.0556 14 16.0556C16.5822 16.0556 18.6667 18.14 18.6667 20.7222C18.6667 23.3044 16.5822 25.3889 14 25.3889ZM18.6667 9.83333H3.11111V3.61111H18.6667V9.83333Z'
        fill={props.checked ? 'black' : 'gray'}
      />
    </svg>
  );
}
