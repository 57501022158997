// -- redux library --
import axios from 'axios';
import { AxiosErrorType, AxiosRequestType, AxiosResponseType } from '@shared/models/AxiosType';
import { cognitoAuth } from 'utils/auth/cognito';
import { updateAccessToken } from 'customJotai/directOperator/access_token';
import { logOut } from 'utils/auth/auth';

// -- ready handler --
const onReady = (request: AxiosRequestType) => {
  return request;
};

// -- success handler --
const onSuccess = (response: AxiosResponseType) => {
  return response;
};

// -- error handler --
const onError = (err: AxiosErrorType) => {
  if (!err) {
    return;
  }

  switch (err.response.status) {
    case 400: // 入力データに誤りがある場合
      break;

    case 401: // ユーザーが認証されていない場合
      return cognitoAuth
        .getAccessToken()
        .then((access_token) => {
          if (access_token) {
            updateAccessToken(access_token);
          }

          // 再度リクエスト
          const config = err.config;
          if (typeof config.data === 'string') {
            config.data = JSON.parse(config.data);
          }
          config.headers['Authorization'] = `Bearer ${access_token}`;

          return axios.request(config);
        })
        .catch(async (error) => {
          console.log(error);
          await logOut();
        });

    default:
      // 予期していないエラー
      break;
  }
  return err.response;
};

// -- export part --
export { onReady, onSuccess, onError };
