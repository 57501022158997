import { Table, Input, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useObj } from '@shared/hooks/useObj/useObj';
import { UserSearchSettingModal } from 'components/UserSearchSettingModal';
import { UserSearchSetting } from 'components/UserSearchSettingModal/UserSearchSelect';
import { useUsers } from 'hooks/useUsers/useUsers';
import { User, usersIdGetAPI } from 'api/users';
import UserCreateDialog from './UserCreateDialog';
import { MenuOutlined } from '@ant-design/icons/lib/icons';

// -- type declaration --
type TableUserProps = {
  onModalOpenChange(is_modal_open: boolean): void;
  selectUser(user: User | undefined): void;
  is_reload_users: boolean;
  reloadUsers(): void;
};

// ユーザテーブルのtype
type TableUserType = {
  id: number;
  user_name: string;
  mail_address: string;
  tenat_name: string;
  role: string;
  status: string;
};

// -- main component --
/**
 * ユーザ一覧の表示テーブルです。
 */
function UsersTable(params: TableUserProps) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableUserType[] | undefined>(undefined);
  const [search_query, setSearchQuery] = useState('');
  const [is_modal_open, setIsModalOpen] = useState(false);
  const { users, loadUsers } = useUsers();
  const { obj: form, updateObj: updateForm } = useObj<UserSearchSetting>({
    user_name: '',
    mail_address: '',
    role: '',
    tenant_id: undefined,
  });

  const handleUserNameClick = async (user_id: number) => {
    const res = await usersIdGetAPI({ user_id: user_id });
    if (res.status === 200) {
      params.selectUser(res.data);
      params.onModalOpenChange(true);
    }
  };

  // -- reload function --
  useEffect(() => {
    if (params.is_reload_users) {
      loadUsers();
      params.reloadUsers();
    }
  }, [params.is_reload_users]); /* eslint-disable-line */

  // テーブルの列の情報まとめたデータ

  const headers = [
    {
      title: 'テナント名',
      dataIndex: 'tenat_name',
      key: 'tenat_name',
    },
    {
      title: 'ユーザ名',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (text: string, record: TableUserType) => <a onClick={() => handleUserNameClick(record.id)}>{text}</a>,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'mail_address',
      key: 'mail_address',
    },
    {
      title: '権限',
      key: 'role',
      dataIndex: 'role',
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  // 検索Modal用関数
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onModalFinish = (form: UserSearchSetting) => {
    updateForm(form);
    setIsModalOpen(false);
    loadTableBodies(form);
  };

  const loadTableBodies = useCallback(
    (search_setting: UserSearchSetting = form) => {
      // 値渡しする (sort()が破壊的メソッドのため)
      let filtered_users = users ? [...users] : undefined;

      // search_queryでフィルター
      if (search_query) {
        filtered_users = filtered_users?.filter((user) =>
          user.user_name.toLowerCase().includes(search_query.toLowerCase()),
        );
      }

      // user_nameでフィルター
      if (search_setting.user_name) {
        filtered_users = filtered_users?.filter((user) =>
          user.user_name.toLowerCase().includes(search_setting.user_name.toLowerCase()),
        );
      }

      // mail_addressでフィルター
      if (search_setting.mail_address) {
        filtered_users = filtered_users?.filter((user) =>
          user.mail_address.toLowerCase().includes(search_setting.mail_address.toLowerCase()),
        );
      }

      // roleでフィルター
      if (search_setting.role) {
        filtered_users = filtered_users?.filter((user) => user.role === search_setting.role);
      }

      // tenant_idでフィルター
      if (search_setting.tenant_id) {
        filtered_users = filtered_users?.filter((user) => user.tenant_id === search_setting.tenant_id);
      }

      // テーブルデータを更新
      setTableBodies(
        filtered_users?.map((user) => ({
          id: user.user_id,
          user_name: user.user_name,
          mail_address: user.mail_address,
          tenat_name: user.tenant_name ?? '',
          role: user.role,
          status: user.status,
        })),
      );
    },
    [form, users, search_query],
  );

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [form, users, search_query]); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      {table_bodies ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Input.Search
              placeholder='カメラ名で検索'
              onSearch={(value) => setSearchQuery(value)}
              style={{ marginBottom: '16px', width: '30%' }}
              addonAfter={
                <MenuOutlined style={{ width: 24, height: 24, verticalAlign: 'middle' }} onClick={openModal} />
              }
            />
            <UserSearchSettingModal
              open={is_modal_open}
              onCancel={closeModal}
              onFinish={onModalFinish}
              initial_form={form}
            />
            <UserCreateDialog reload={() => params.reloadUsers()} />
          </div>
          <Table columns={headers} dataSource={table_bodies.map((body, index) => ({ ...body, key: index }))} />
        </>
      ) : (
        <Spin />
      )}
    </>
  );
}

// -- finally export part --

export default UsersTable;
