import { deleteAccessToken } from 'customJotai/directOperator/access_token';
import { deleteUser } from 'customJotai/directOperator/user';
import { cognitoAuth } from './cognito';
import history from '@shared/browserHistory';

/**
 * logOutする
 */
export const logOut = async () => {
  await cognitoAuth.signOut();
  deleteAccessToken();
  deleteUser();
  history.push('/login');
};
