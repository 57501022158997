// -- external images --
import history from '@shared/browserHistory';

// -- external functions --
import { useObj } from '@shared/hooks/useObj/useObj';
import { Button, Typography } from 'antd';
import { useEffect } from 'react';
import { logOut } from 'utils/auth/auth';

export interface TopMenuItem {
  id: string;
  text: string;
  img_src: string;
  // 管理者ユーザー向けのページの場合true
  for_admin?: boolean;
  url: string;
}
interface DgTopMenuProps {
  default_id: string;
  top_menu_items: TopMenuItem[];
  is_idadmin_user: boolean;
}
interface DgTopMenuState {
  selected_id: string;
  is_idadmin: boolean;
}

/**
 * トップメニュー
 */
export default function DgTopMenu({ default_id, top_menu_items, is_idadmin_user }: DgTopMenuProps) {
  const getIdFromUrl = () => {
    const query_params = new URLSearchParams(window.location.search);
    const qp = query_params.get('qp');
    // query parameterからのqp値があるときはそれを優先してidとして返却
    if (qp) {
      return qp;
    }
    const pathnames = window.location.pathname.split('/');
    // pathnameの2番目
    return pathnames.length > 2 ? pathnames[2] : '';
  };
  const { obj: state, updateObj: updateState } = useObj<DgTopMenuState>({
    selected_id: default_id,
    is_idadmin: is_idadmin_user,
  });

  const onMenuClick = (top_menu_item: TopMenuItem) => {
    history.push(top_menu_item.url);
  };

  const setId = () => {
    const new_id = getIdFromUrl();
    updateState({ selected_id: new_id });
  };

  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen(setId);

    // stop the listener when component unmounts
    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { is_idadmin } = state;
  return (
    <div
      style={{
        transition: 'width 0.3s, margin 0.3s',
        transitionTimingFunction: 'ease-out',
        display: 'flex',
        justifyContent: 'space-betweem',
        alignItems: 'center',
        padding: '0px 5px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          height: 60,
          width: '100%',
          boxShadow: 'none',
        }}
      >
        {top_menu_items.map((top_menu_item) => {
          if (top_menu_item.for_admin && !is_idadmin) return null;
          return (
            <MenuItem
              key={top_menu_item.id}
              id={top_menu_item.id}
              text={top_menu_item.text}
              img_src={top_menu_item.img_src}
              selected={state.selected_id === top_menu_item.id}
              onClick={() => onMenuClick(top_menu_item)}
            />
          );
        })}
      </div>
      <Button shape='round' type='primary' danger size='small' title='ログアウト' onClick={logOut}>
        ログアウト
      </Button>
    </div>
  );
}

/**
 * メニューアイテム
 */
interface MenuItemProps {
  id: string;
  text: string;
  img_src: string;
  selected: boolean;
  onClick(id: string): void;
}
function MenuItem({ id, text, img_src, selected, onClick }: MenuItemProps) {
  return (
    <div
      onClick={() => onClick(id)}
      id={id}
      style={{
        width: 80,
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        alignItems: 'center',
        backgroundColor: selected ? 'rgba(57, 131, 141, 1)' : 'white',
        cursor: 'pointer',
        fontWeight: 'bold',
        borderRight: '1px solid rgb(236 236 236)',
      }}
    >
      <img src={img_src} style={{ width: 25, height: 25 }} />
      <Typography.Text style={{ marginTop: 4, fontSize: 'smaller' }}>{text}</Typography.Text>
    </div>
  );
}
