import { Table, Input, Spin } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useObj } from '@shared/hooks/useObj/useObj';
import { CameraSearchSetting } from 'components/CameraSearchSettingModal/CameraSearchSelect';
import { CameraSearchSettingModal } from 'components/CameraSearchSettingModal';
import { Camera, CamerasMaps } from 'api/cameras';
import { MenuOutlined } from '@ant-design/icons';
import CheckCamerasDialog from './CheckCamerasDialog';
import { useCamerasMap } from 'hooks/useCameras/useCamerasMap';
import { CameraStatus, cameraStatusToJP } from '@shared/models/CameraStatus';

// -- type declaration --
type TableCameraProps = {
  onModalOpenChange(is_modal_open: boolean): void;
  selectCamera(camera: Camera | undefined): void;
  is_reload_cameras: boolean;
  reloadCameras(): void;
};

// -- main component --
/**
 * カメラ一覧の表示テーブルです。
 */
const CamerasTable = memo(function CamerasTable(params: TableCameraProps) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<CamerasMaps[] | undefined>(undefined);
  const [search_query, setSearchQuery] = useState('');
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [selected_cameras, setSelectedCameras] = useState<CamerasMaps[]>([]);
  const { cameras, loadCameras } = useCamerasMap();
  const { obj: form, updateObj: updateForm } = useObj<CameraSearchSetting>({
    camera_name: '',
    tenant_id: undefined,
    map_id: [],
  });

  // ソート用の重み付け
  const status_weight = useMemo(
    () => ({
      active: 1,
      preparing: 2,
      deleted: 3,
    }),
    [],
  );

  const handleCameraNameClick = (camera: Camera) => {
    params.selectCamera(camera);
    params.onModalOpenChange(true);
  };

  const checkCameras = {
    selectedRowKeys: selected_cameras.map((camera) => camera.camera_id),
    onChange: (_selected_camera_ids: React.Key[], new_selected_cameras: CamerasMaps[]) => {
      // 型を修正しました。
      setSelectedCameras(new_selected_cameras);
    },
  };

  // テーブルの列の情報まとめたデータ

  const headers = [
    {
      title: 'カメラ名',
      dataIndex: 'camera_name',
      key: 'camera_name',
      render: (text: string, record: CamerasMaps) => <a onClick={() => handleCameraNameClick(record)}>{text}</a>,
    },
    {
      title: '住所',
      dataIndex: 'camera_address',
      key: 'camera_address',
    },
    {
      title: 'バージョン',
      dataIndex: 'application_version',
      key: 'application_version',
    },
    {
      title: '全体公開設定',
      key: 'is_open',
      dataIndex: 'is_open',
      render: (is_open: boolean) => <>{is_open ? '公開' : '非公開'}</>,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      render: (status: CameraStatus) => <>{cameraStatusToJP(status)}</>,
    },
  ];

  // 検索Modal用関数
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onModalFinish = (form: CameraSearchSetting) => {
    updateForm(form);
    setIsModalOpen(false);
    loadTableBodies(form);
  };

  const loadTableBodies = useCallback(
    async (search_setting: CameraSearchSetting = form) => {
      let filtered_cameras = cameras;

      // search_queryでフィルター
      if (search_query) {
        filtered_cameras = filtered_cameras?.filter((camera) =>
          camera.camera_name.toLowerCase().includes(search_query.toLowerCase()),
        );
      }

      // camera_nameでフィルター
      if (search_setting.camera_name) {
        filtered_cameras = filtered_cameras?.filter((camera) =>
          camera.camera_name.toLowerCase().includes(search_setting?.camera_name?.toLowerCase()),
        );
      }

      // tenant_idでフィルター
      if (search_setting.tenant_id) {
        filtered_cameras = filtered_cameras?.filter((camera) => camera.tenant_id === search_setting.tenant_id);
      }

      // map_idでフィルター
      if (search_setting.map_id && search_setting.map_id.length > 0) {
        // 関連するcamera_idでフィルタリング
        filtered_cameras = filtered_cameras?.filter(
          (camera) =>
            // camera.map_camera配列とsearch_setting.map_id配列の間に共通する要素があるか確認
            camera.map_cameras && search_setting.map_id.some((map_id) => camera.map_cameras.includes(map_id)),
        );
      }

      // statusの項目でソート
      filtered_cameras?.sort((a, b) => {
        return status_weight[a.status] - status_weight[b.status];
      });

      // テーブルデータを更新
      setTableBodies(filtered_cameras?.map((camera) => camera));
    },
    [form, cameras, search_query, status_weight],
  );
  // -- reload function --
  useEffect(() => {
    if (params.is_reload_cameras) {
      loadCameras();
      params.reloadCameras();
    }
  }, [params.is_reload_cameras]); /* eslint-disable-line */

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [cameras, search_query, form]); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      {table_bodies ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Input.Search
              placeholder='カメラ名で検索'
              onSearch={(value) => setSearchQuery(value)}
              style={{ marginBottom: '16px', width: '30%' }}
              addonAfter={
                <MenuOutlined style={{ width: 24, height: 24, verticalAlign: 'middle' }} onClick={openModal} />
              }
            />
            <CameraSearchSettingModal
              open={is_modal_open}
              onCancel={closeModal}
              onFinish={onModalFinish}
              initial_form={form}
            />
          </div>
          <Table
            rowKey='camera_id'
            rowSelection={checkCameras}
            columns={headers}
            dataSource={table_bodies?.map((body, index) => ({ ...body, key: index }))}
          />
          <CheckCamerasDialog cameras={selected_cameras} reloadCameras={params.reloadCameras} />
        </>
      ) : (
        <Spin />
      )}
    </>
  );
});

// -- finally export part --

export default CamerasTable;
