import { useState } from 'react';
import { Button, Modal, message } from 'antd';
import { useTenants } from 'hooks/useTenants/useTenants';
import { usersIdPostAPI } from 'api/users';
import {
  MailAddressTextAreaForm,
  RoleSelectForm,
  TenantSelectForm,
  UserNameTextAreaForm,
} from 'components/UserDialog/commons';

type UserCreateDialogProps = {
  reload(): void;
};

// -- main component --
/**
 * テナント一覧の表示テーブルです。
 */

const UserCreateDialog = (params: UserCreateDialogProps) => {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [user_name, setUserName] = useState<string>('');
  const [mail_address, setMailAddress] = useState<string>('');
  const [role, setRole] = useState<string>('admin');
  const { tenants } = useTenants();
  const [tenant_id, setTenantId] = useState<number>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (!tenant_id || !user_name || !mail_address) {
      message.error('必須項目を設定してください');
    } else {
      await usersIdPostAPI({
        tenant_id,
        user_name,
        mail_address,
      });
      params.reload();
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        新規ユーザ登録
      </Button>
      <Modal
        title='ユーザ新規追加'
        open={is_modal_open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='登録'
        cancelText='キャンセル'
      >
        <UserNameTextAreaForm value={user_name} onChange={(e) => setUserName(e.currentTarget.value)} />
        <MailAddressTextAreaForm value={mail_address} onChange={(e) => setMailAddress(e.currentTarget.value)} />
        <RoleSelectForm value={role} onChange={(value) => setRole(value)} />
        {role === 'admin' && (
          <TenantSelectForm value={tenant_id} onChange={(value) => setTenantId(value)} tenants={tenants} />
        )}
      </Modal>
    </>
  );
};

export default UserCreateDialog;
