import { Col, Row, Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { ReactNode } from 'react';

const { Text } = Typography;

export interface TitleTaggedComponentProps {
  children: ReactNode;
  title: string;
  direction?: 'vertical' | 'horizontal';
  text_props?: TextProps;
}
export function TitleTaggedComponent(props: TitleTaggedComponentProps) {
  const { children, title, text_props, direction = 'horizontal' } = props;
  if (direction === 'horizontal') {
    return (
      <Row align='middle' style={{ height: '100%', width: '100%' }}>
        <Col span={5}>
          <Text {...text_props}>{title}</Text>
        </Col>
        <Col span={1}>
          <Text>：</Text>
        </Col>
        <Col span={18}>{children}</Col>
      </Row>
    );
  } else if (direction === 'vertical') {
    return (
      <Row gutter={[0, 8]} align='middle' justify='center' style={{ height: '100%' }}>
        <Col span={24} style={{ height: 24 }}>
          <Text underline {...text_props}>
            {title}
          </Text>
        </Col>
        <Col span={24} style={{ height: 'calc(100% - 24px - 8px)' }}>
          {children}
        </Col>
      </Row>
    );
  }
  return null;
}

export interface TitleTaggedComponentItemsProps {
  items: TitleTaggedComponentProps[];
}

export function TitleTaggedComponentItems({ items }: TitleTaggedComponentItemsProps) {
  return (
    <Row gutter={[8, 8]}>
      {items.map((item, i) => {
        return (
          <Col span={24} key={i}>
            <TitleTaggedComponent title={item.title}>{item.children}</TitleTaggedComponent>
          </Col>
        );
      })}
    </Row>
  );
}
