import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { Tenant, tenantsIdPutAPI } from 'api/tenants';
import { Camera } from 'api/cameras';
import { Map } from 'api/maps';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useUsers } from 'hooks/useUsers/useUsers';
import { User } from 'api/users';
import { TenantNameTextAreaForm, UserListView } from 'components/TenantDialog/commons';
import { CameraListView } from 'components/ListView/CameraListView';
import { MapListView } from 'components/ListView/MapListView';

// デバイステーブルのtype
type DaialogTenantProps = {
  tenant: Tenant;
  is_modal_open: boolean;
  onClose(update: boolean): void;
};

// -- main component --
/**
 * テナント詳細ダイアログです。
 */

const TenantDetailDialog = (params: DaialogTenantProps) => {
  const { cameras } = useCameras();
  const { maps } = useMaps(false);
  const { users } = useUsers();
  const [camera_list, setCameraList] = useState<Camera[]>([]);
  const [map_list, setMapList] = useState<Map[]>([]);
  const [user_list, setUserList] = useState<User[]>([]);
  const [tenant_name, setTenantName] = useState<string>(params.tenant.tenant_name);

  const handleOk = async () => {
    const res = await tenantsIdPutAPI({
      tenant_id: params.tenant.tenant_id,
      tenant_name: tenant_name,
    });
    if (res.status == 200) params.onClose(true);
  };

  const handleCancel = () => {
    params.onClose(false);
  };

  useEffect(() => {
    if (cameras) {
      const new_camera_list = cameras.filter((camera) => camera.tenant_id === params.tenant.tenant_id);
      setCameraList(new_camera_list);
    }
    if (maps) {
      const new_map_list = maps.filter((map) => map.tenant_id === params.tenant.tenant_id).filter((map) => map.is_open);
      setMapList(new_map_list);
    }
    if (users) {
      const new_user_list = users.filter((user) => user.tenant_id === params.tenant.tenant_id);
      setUserList(new_user_list);
    }
  }, [cameras, maps, params.tenant.tenant_id, users]);

  return (
    <Modal
      title={`${params.tenant.tenant_name}の詳細`}
      open={params.is_modal_open}
      width={'50%'}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='登録'
      cancelText='キャンセル'
    >
      <p>テナントID：{params.tenant.tenant_id}</p>
      <TenantNameTextAreaForm value={tenant_name} onChange={(e) => setTenantName(e.currentTarget.value)} />
      <Row gutter={16}>
        <Col span={8}>
          <UserListView users={user_list} />
        </Col>
        <Col span={8}>
          <CameraListView cameras={camera_list} />
        </Col>
        <Col span={8}>
          <MapListView maps={map_list} />
        </Col>
      </Row>
    </Modal>
  );
};

export default TenantDetailDialog;
