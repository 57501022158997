export interface MapSvgProps extends React.SVGProps<SVGSVGElement> {}
export function MapSvg(props: MapSvgProps) {
  return (
    <svg
      {...props}
      width='53'
      height='53'
      viewBox='0 0 53 53'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect width='53' height='53' fill='url(#pattern0)' />
      <defs>
        <pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
          <use xlinkHref='#image0_912_1413' transform='scale(0.0104167)' />
        </pattern>
        <image
          id='image0_912_1413'
          width='96'
          height='96'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFIElEQVR4nO2dy2tdRRzHPxSTVlCxAYtuFB9pFaUgogvtUtwEsaK1loKv/AEuXOhSG0WR6lLQ2oWupIgPJCqo1IWilBbEghtNKGh9JD7iI6Bpc0cGfleul5t7Zs49v5kzc+cDPwiXk5nfb75z5syZ14FClwuAfcAh4BiwBKyJLclvLwH3Auf/91+FkdkOHAZWAeNo9tqXgenRsx9fzgUOAmc8Cr7f7N3xLLAldjCpMQ2cHKHg++0z4JLYQaXC9dKmm4btW2Bn7OBSqPlLCoXfK8LFsYNsK1uALxQLv2vH5flS6ONggMLv2hP9mY8720fs7fjan6Up+j+HAxZ+117o82Gs33BXPQvvG2lG7hY7ACx4pvEXcF7s4NvAPo9C60hhTwxIx/42J9e4preXBDgH2A28BSwDPwLzwEPAVAPpH/IoMFv4VTzpkd6LtJjLpEadrnjVf29EMY55NDuDan4/kx7N0ee0tLa/C6x7tql1xVhW6DoecEzzJxKq7aamGFX845imfdi6sscxzb+JyCbgVuCIch98ssKPVQUB7vHoCSVf202FXVThzymFJmjOMU2bd+vbdjOiXVvh21HHdBY8HsKLjmnavLOq7WaA3VXh49MeadlYqnjKIz2bt9qw7ptKtX1W7qQ1x+sfr/B1xiPvjvTzBz1XJqXwfV7EZrQK37VrV8e62K7mgw5ifMhwNgMrnj4syN2wR2zOo9np2ork3ThHFAvfbJDn1h4x+ruVqw5zsq8o+zzIbJ4q/BxBgF4uBO4D3ukR43aGc0sEAW5GCW3HfbB3xgPA/Q7XHg9Y+DYvxkEAH/YGFEB1FDRVATYFmhM+KXmpkaoAljsC+G/zUCVlASzvK/r+EQFIXYDrlAYHz0ja6qQugO9wgqvZN+gg5CDAJPBlgz5/FXKBbg4CdNeIrjXU9NxIQHIRoKmmKFjTk6MAm4ETI/h6QmvAbVwEsFxZY7TU2h/ADiKQmwDIYJ7POH/HYSJIjRwFsDzv4eNzRCRXASYc54+POs4dq5GrAN1ZuK+H+LbosApDnZwFsFwN/LbBQzfIUMO4C2C5re8lbU1+awWpC7DT8br90tvpyN9Npj3WApwCtjle+4iYC9tCrYRLXQADfNrwG6wd3Ps4VBOagwAGeLXBNHs3gaiTiwAGeLSB9B4L7H9WAnRkwVdd9g9YnqlOTgIY4CxwZ410ZjaYT1AnNwGMLG/c5ZHGriEbP9TJUQAD/A7c5DiT9mtE/7MVwMi8wA0VL1q/RPY/awGMLL0ftOtmh+xhju1/9gIY2V56Td+eCNddQK0IIHUBjBy+dAVwqcdGvyKAAz6VYbHGzhh1xuUOMC31P/kATOL+Jx+ASdz/5AMwifuffAAmcf+TD8Ak7n/yAZjE/U8+AJO4/8kHYBL3P/kATOL+Jx+ASdx/9QCmEvdfHe0AzgKfAA97LKBqk//qaAdglMUoAkQWw5Q7IO6dYYoAcZspo2h2PjmrZ4BRON5YM//Xxl0A4yCGVn7240BXFQEYKsasQsGvyxGedr9xELRq0HdyfPCsFFYT5zho2mk5ztIeXhuUpmvPB3I258SAA/n6T0eMbcP8DUYTgXwPPANc7phnbDF+8PRXldi1Z2sgMVpR2wexolzbY4vRqto+iDcca8+8nCBoj7YPwZR0Oes8wNflWOTdAf2tzfSQ9fHRegY1xWiLv7U+Dfi6fLLPbuF/u8W1Z0rEmJdVz8vyyay2+lsoFAqFQqFQKBQKhUKhUCBP/gUzplwQfx15DgAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
}
