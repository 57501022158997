import { DgAdminTopMenu } from 'components/DgAdminTopMenu';
import { AuthProvider } from './AuthProvider';

export interface DgIdAdminDocumentProps {
  children?: React.ReactNode;
}

export function DgIdAdminDocument({ children }: DgIdAdminDocumentProps) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ height: 60, width: '100%', paddingBottom: 10, borderBottom: '1px solid rgb(236, 236, 236)' }}>
          <DgAdminTopMenu />
        </div>
        <div style={{ height: 'calc(100% - 60px - 10px)', width: '100%' }}>{children}</div>
      </div>
      <AuthProvider />
    </div>
  );
}
