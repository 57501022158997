/*** エンティティ ***/

import { sendAxios } from './base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601 } from '@shared/models/ISO8601';
// import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Camera } from './cameras';

export interface Map {
  map_id: number;
  map_name: string;
  map_description: string;
  tenant_id: number;
  is_open: boolean;
  parent_map_id: number | null;
  is_facility: boolean;
  facility_name: string;
  status: 'active' | 'delete';
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface MapCamera {
  map_id: number;
  camera_id: number;
  facility_position_x: number;
  facility_position_y: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMap = (data: any): data is Map => typeof data?.map_id === 'number';

// const sample_map1: Map = {
//   map_id: 1,
//   map_name: 'map 1',
//   map_description: 'This is the first map.',
//   tenant_id: 1,
//   is_open: true,
//   parent_map_id: null,
//   is_facility: false,
//   facility_name: 'pure',
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map2: Map = {
//   map_id: 2,
//   map_name: 'map 2',
//   map_description: 'This is the second map.',
//   tenant_id: 2,
//   is_open: true,
//   parent_map_id: null,
//   is_facility: true,
//   facility_name: 'pure',
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map3: Map = {
//   map_id: 3,
//   map_name: 'map 3',
//   map_description: 'This is the third map.',
//   tenant_id: 3,
//   is_open: false,
//   parent_map_id: null,
//   is_facility: true,
//   facility_name: 'pure',
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_map4: Map = {
//   map_id: 4,
//   map_name: 'map 4',
//   map_description: 'This is the forth map.',
//   tenant_id: 4,
//   is_open: true,
//   parent_map_id: 2,
//   is_facility: true,
//   facility_name: 'pure',
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

/*** [GET] /idadmin/maps ***/
export interface RequestMapsGet extends CommonAPIRequestType {
  parent_map_id?: number;
  only_area?: boolean;
  tenant_id?: number;
}

export const mapsGetAPI = (params: RequestMapsGet) => {
  // const {} = toAPIRequestParams(params);
  const { parent_map_id, only_area, tenant_id } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `idadmin/maps`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { parent_map_id, only_area, tenant_id };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: getSampleMap(params.parent_id),
  });
};

/*** [GET] /maps/{map_id} ***/
export interface RequestMapsIdGet extends CommonAPIRequestType {
  map_id: number;
}

export const mapsIdGetAPI = (params: RequestMapsIdGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_map1,
  });
};

/*** [POST] /maps ***/
export interface RequestMapsPost extends CommonAPIRequestType {
  map_name: string;
  tenant_id: number;
  map_description: string;
  is_facility: boolean;
  is_open: boolean;
  parent_map_id?: number;
  facility_name?: string;
}

export const mapsPostAPI = (params: RequestMapsPost) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_map1, sample_map2, sample_map3, sample_map4],
    // [sample_map1, sample_map2, sample_map3, sample_map4],
  });
};

/*** [PUT] /maps/{map_id} ***/
export interface RequestMapsPut extends CommonAPIRequestType {
  map_id: number;
  map_name: string;
  tenant_id: number;
  map_description: string;
  is_facility: boolean;
  is_open: boolean;
  parent_map_id?: number;
  facility_name?: string;
}

export const mapsIdPutAPI = (params: RequestMapsPut) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_map1, sample_map2, sample_map3, sample_map4],
    // [sample_map1, sample_map2, sample_map3, sample_map4],
  });
};

/*** [GET] /maps/{map_id} ***/
export interface RequestMapsIdCamerasGet extends CommonAPIRequestType {
  map_id: number;
}

export const mapsIdCmamerasGetAPI = (params: RequestMapsIdCamerasGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/map_cameras/${params.map_id}/cameras`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Camera[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [1, 3, 5],
  });
};

/*** [DELETE] /maps/{map_id} ***/
export interface RequestMapsIdDelete extends CommonAPIRequestType {
  map_id: number;
}

export const mapsIdDeleteAPI = (params: RequestMapsIdDelete) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<''>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [GET] /maps/{map_id}/facility ***/
export interface RequestMapsIdFacilityGet extends CommonAPIRequestType {
  map_id: number;
}

export const mapsIdFacilityGetAPI = (params: RequestMapsIdFacilityGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}/facility`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_map2, sample_map4],
  });
};

/*** [POST] /map_cameras/{map_id}/cameras/{camera_id} ***/
export interface RequestMapsIdCamerasPost extends CommonAPIRequestType {
  map_id: number;
  camera_id: number;
}

export const mapsIdCameraPostAPI = (params: RequestMapsIdCamerasPost) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}/cameras/${params.camera_id}`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<MapCamera>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [DELETE] /map_cameras/{map_id} ***/
export interface RequestMapsIdCamerasDelete extends CommonAPIRequestType {
  map_id: number;
  camera_id: number;
}

export const mapsIdCameraDeleteAPI = (params: RequestMapsIdCamerasDelete) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `maps/${params.map_id}/cameras/${params.camera_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<''>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};

/*** [DELETE] /idadmin/map_cameras/cameras/{camera_id} ***/
export interface RequestMapCamerasCamerasIdDelete extends CommonAPIRequestType {
  camera_id: number;
}

export const mapCamerasCamerasIdDeleteAPI = (params: RequestMapCamerasCamerasIdDelete) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/map_cameras/cameras/${params.camera_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<''>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: ['00000001', '00000002', '00000003', '00000005'],
  });
};
