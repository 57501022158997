import { Modal, ModalProps, Space, Row, Col, Button } from 'antd';
import { ReactNode } from 'react';

export interface ModalInputProps extends ModalProps {
  children: ReactNode;
  onDisplay(): void;
}
export function ModalInputBase(props: ModalInputProps) {
  const { onDisplay } = props;
  return (
    <Modal {...props} footer={null} centered={false} mask={false} width={400}>
      <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
        {props.children}
        <Row justify='center' align='middle'>
          <Col>
            <Button type='primary' shape='round' style={{ width: 180 }} onClick={onDisplay}>
              表示
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
}
