/*** エンティティ ***/

import { sendAxios } from './base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Content } from '@shared/models/Content';
import { Status } from '@shared/models/Status';
import { Age } from '@shared/models/Age';
import { ViewType } from '@shared/models/ViewType';
import { Unit } from '@shared/models/Unit';
import { Period } from '@shared/models/Period';
import { Role } from '@shared/models/Role';

export interface User {
  user_id: number;
  tenant_id: number;
  tenant_name: string | null;
  user_name: string;
  mail_address: string;
  home_page: ViewType;
  home_address: string;
  display_unit: Unit;
  display_period: Period;
  start_year: number | null;
  start_day: number | null; // 曜日
  start_time: number | null;
  start_month: number | null;
  start_date: number | null;
  is_display_content: boolean;
  display_content: Content[] | null;
  display_age: Age[];
  circle_color: string;
  role: Role;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

/*** [GET] /users/{user_id} ***/
export interface RequestUsersIdGet extends CommonAPIRequestType {}

export const sample_user1: User = {
  user_id: 1,
  tenant_id: 1,
  tenant_name: 'tenant0',
  user_name: 'johndoe1',
  mail_address: 'johndoe1@example.com',
  home_page: 'urban',
  home_address: '123 Main Street',
  display_unit: 'hour',
  display_period: 'period',
  start_year: 2023,
  start_day: 1,
  start_time: 8,
  start_month: 2,
  start_date: 1,
  is_display_content: true,
  display_content: [],
  display_age: ['child'],
  circle_color: '#FFA500',
  role: 'admin',
  status: 'active',
  created_at: toISO8601(new Date()),
  updated_at: toISO8601(new Date()),
  deleted_at: null,
};

// export const sample_user2: User = {
//   user_id: '00000002',
//   tenant_id: '00000001',
//   login_id: 'johndoe2',
//   mail_address: 'johndoe2@example.com',
//   home_page: 'urban',
//   home_address: '123 Main Street',
//   display_unit: 'hour',
//   display_period: 'period',
//   start_day: 1,
//   start_time: 8,
//   start_month: 2,
//   start_date: 'sun',
//   is_display_content: true,
//   display_content: [],
//   display_age: ['child'],
//   circle_color: '#FFA500',
//   role: 'general',
//   status: 'active',
//   created_at: toISO8601(new Date()),
//   updated_at: toISO8601(new Date()),
//   deleted_at: null,
// };

// export const sample_user3: User = {
//   user_id: '00000003',
//   tenant_id: '00000002',
//   login_id: 'johndoe3',
//   mail_address: 'johndoe3@example.com',
//   home_page: 'urban',
//   home_address: '123 Main Street',
//   display_unit: 'hour',
//   display_period: 'period',
//   start_day: 1,
//   start_time: 8,
//   start_month: 2,
//   start_date: 'sun',
//   is_display_content: true,
//   display_content: [],
//   display_age: ['child'],
//   circle_color: '#FFA500',
//   role: 'admin',
//   status: 'active',
//   created_at: toISO8601(new Date()),
//   updated_at: toISO8601(new Date()),
//   deleted_at: null,
// };

export const usersGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `idadmin/users`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_user1, sample_user3],
  });
};

/*** [GET] /users/{user_id} ***/
export interface RequestUsersIdGet extends CommonAPIRequestType {
  user_id: number;
}

export const usersIdGetAPI = (params: RequestUsersIdGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `users/${params.user_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_user1,
  });
};

/*** [PUT] /users/{user_id} ***/
export interface RequestUsersIdPut extends CommonAPIRequestType {
  user_id: number;
  tenant_id: number;
  user_name?: string;
  mail_address?: string;
  home_page?: ViewType;
  home_address?: string;
  display_unit?: Unit;
  display_period?: Period;
  start_year?: number | null;
  start_day?: number | null;
  start_time?: number | null;
  start_month?: number | null;
  start_date?: number | null;
  is_display_content?: boolean;
  display_content?: Content[];
  display_age?: Age[];
  circle_color?: string;
  role?: Role;
}

export const usersIdPutAPI = (params: RequestUsersIdPut) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `users/${params.user_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(remain)) {
  //   addFormData({ form, key, value });
  // }

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_user1,
  });
};

/*** [POST] /users ***/
export interface RequestUsersIdPost extends CommonAPIRequestType {
  tenant_id: number;
  user_name?: string;
  mail_address?: string;
  home_page?: ViewType;
  home_address?: string;
  display_unit?: Unit;
  display_period?: Period;
  start_year?: number | null;
  start_day?: number | null;
  start_time?: number | null;
  start_month?: number | null;
  start_date?: number | null;
  is_display_content?: boolean;
  display_content?: Content[];
  display_age?: Age[];
  circle_color?: string;
  role?: Role;
}

export const usersIdPostAPI = (params: RequestUsersIdPost) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/users`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(remain)) {
  //   addFormData({ form, key, value });
  // }

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_user1,
  });
};
