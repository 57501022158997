import TenantsTable from './TenantTable';
import { useState } from 'react';
import { Tenant } from 'api/tenants';
import TenantDetailDialog from './TenantDetailDialog';

export default function TenantsPage() {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [is_reload_tenants, setReloadTenants] = useState(false);
  const [select_tenant, setSelectTenant] = useState<Tenant | undefined>();

  const onModalClose = (update: boolean) => {
    setIsModalOpen(false);
    setSelectTenant(undefined);
    if (update) reloadTenants();
  };

  const reloadTenants = () => {
    setReloadTenants(!is_reload_tenants);
  };

  return (
    <div style={{ margin: 10 }}>
      <TenantsTable
        onModalOpenChange={setIsModalOpen}
        selectTenant={setSelectTenant}
        is_reload_tenants={is_reload_tenants}
        reloadTenants={reloadTenants}
      />
      {select_tenant && (
        <TenantDetailDialog tenant={select_tenant} is_modal_open={is_modal_open} onClose={onModalClose} />
      )}
    </div>
  );
}
