/*** エンティティ ***/

import { sendAxios } from './base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import getClient from './base';
import { Camera } from './cameras';
import { User } from './users';
import { Map } from './maps';

export interface Tenant {
  tenant_id: number;
  tenant_name: string;
}

export interface Tenants {
  tenant_id: number;
  tenant_name: string;
  cameras: Camera[];
  users: User[];
  maps: Map[];
}

// const sample_tenant1: Tenant = {
//   tenant_id: '00000001',
//   tenant_name: 'Tenant 1',
// };

// const sample_tenant2: Tenant = {
//   tenant_id: '00000002',
//   tenant_name: 'Tenant 2',
// };

// const sample_tenant3: Tenant = {
//   tenant_id: '00000003',
//   tenant_name: 'Tenant 3',
// };

// const sample_tenant4: Tenant = {
//   tenant_id: '00000004',
//   tenant_name: 'Tenant 4',
// };

/*** [GET] /tenants ***/
export interface RequestTenantsGet extends CommonAPIRequestType {}

export const tenantsGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `idadmin/tenants`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Tenants[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_tenant1, sample_tenant2, sample_tenant3, sample_tenant4],
  });
};

/*** [PUT] /tenants ***/
export interface RequestTenantsIdPut extends CommonAPIRequestType {
  tenant_id: number;
  tenant_name: string;
}

export const tenantsIdPutAPI = (params: RequestTenantsIdPut) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/tenants/${params.tenant_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(remain)) {
  //   addFormData({ form, key, value });
  // }

  // 送信
  return sendAxios<Tenant>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_tenant1,
  });
};

/*** [POST] /tenants ***/
export interface RequestTenantsPost {
  tenant_name: string;
}

export const tenantsPostAPI = (params: RequestTenantsPost) => {
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/tenants`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // const form = new FormData();
  // for (const [key, value] of Object.entries(tenant)) {
  //   addFormData({ form, key, value });
  // }

  // 送信
  return sendAxios<Tenant>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_tenant1,
  });
};
