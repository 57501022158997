import { Button, Drawer, Form, Modal, Space, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTenants } from 'hooks/useTenants/useTenants';
import TextArea from 'antd/es/input/TextArea';
import { Camera, CameraType, RequestCamerasIdPut, camerasIdPutAPI } from 'api/cameras';
import { LatLng } from 'leaflet';
import { useObj } from '@shared/hooks/useObj/useObj';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';
import {
  CameraDescriptionTextAreaForm,
  CameraFacilityOnlySwitchForm,
  CameraIsOpenSwitchForm,
  CameraNameTextAreaForm,
  CameraSelectForm,
  CameraStatusForm,
  CameraTypeSelectForm,
  TenantSelectForm,
} from './commons';
import { useCamerasByStatus } from 'hooks/useCameras/useCamerasByStatus';
import { mapCamerasCamerasIdDeleteAPI } from 'api/maps';

interface Form {
  tenant_id: number;
  camera_id: number;
  camera_name: string;
  camera_description: string;
  camera_address: string;
  camera_type: CameraType;
  is_open: boolean;
  facility_only: boolean;
}
interface DetailCameraProps {
  open: boolean;
  select_camera: Camera;
  new_camera_position: LatLng | null;
  onClose: (camera?: Camera) => void;
}

const DetailCameraDrawer = (params: DetailCameraProps) => {
  const { tenants, loadTenants } = useTenants();
  const { cameras_by_status, loadCamerasByStatus } = useCamerasByStatus('preparing');
  const { obj: form, updateObj: updateForm } = useObj<Form>({
    tenant_id: params.select_camera.tenant_id,
    camera_id: params.select_camera.camera_id,
    camera_name: params.select_camera.camera_name,
    camera_description: params.select_camera.camera_description,
    camera_address: params.select_camera.camera_address,
    camera_type: params.select_camera.camera_type,
    is_open: params.select_camera.is_open,
    facility_only: params.select_camera.facility_only,
  });
  const { camera_id, camera_name, tenant_id, camera_address, camera_description, camera_type, is_open, facility_only } =
    form;
  const [is_camera_moving, setIsCameraMoving] = useState<boolean>(false);

  useEffect(() => {
    loadTenants();
    loadCamerasByStatus();
  }, []); /* eslint-disable-line */

  const onSetCamera = async () => {
    if (params.select_camera.camera_id != camera_id) {
      Modal.confirm({
        title: '確認',
        content:
          'カメラのが変更されました。\nカメラを変更する場合、元のカメラに設定された情報は削除されます。\n実行しますか？',
        okText: 'OK',
        cancelText: 'キャンセル',
        onOk: async () => {
          await mapCamerasCamerasIdDeleteAPI({ camera_id: params.select_camera.camera_id });
          const new_camera: RequestCamerasIdPut = {
            camera_id,
            camera_name,
            tenant_id,
            camera_address,
            camera_description,
            latitude: !facility_only ? params.select_camera.latitude : null,
            longitude: !facility_only ? params.select_camera.longitude : null,
            camera_type,
            is_open,
            facility_only,
          };
          const res = await camerasIdPutAPI(new_camera);
          const delete_camera: RequestCamerasIdPut = {
            camera_id: params.select_camera.camera_id,
            camera_name: params.select_camera.camera_name,
            tenant_id: params.select_camera.tenant_id,
            camera_address: '',
            camera_description: '',
            latitude: null,
            longitude: null,
            camera_type: params.select_camera.camera_type,
            is_open: false,
            facility_only: false,
            status: 'preparing',
          };
          await camerasIdPutAPI(delete_camera);
          params.onClose(res.data);
        },
      });
    } else if (params.select_camera.tenant_id != tenant_id) {
      Modal.confirm({
        title: '確認',
        content:
          'カメラのテナントIDが変更されました。\nテナントを変更する場合、カメラに紐づけられたエリア情報も削除されます。\n実行しますか？',
        okText: 'OK',
        cancelText: 'キャンセル',
        onOk: async () => {
          await mapCamerasCamerasIdDeleteAPI({ camera_id: camera_id });
          const new_camera: RequestCamerasIdPut = {
            camera_id,
            camera_name,
            tenant_id,
            camera_address,
            camera_description,
            latitude: !facility_only ? params.select_camera.latitude : null,
            longitude: !facility_only ? params.select_camera.longitude : null,
            camera_type,
            is_open,
            facility_only,
          };
          const res = await camerasIdPutAPI(new_camera);
          params.onClose(res.data);
        },
      });
    } else {
      const new_camera: RequestCamerasIdPut = {
        camera_id,
        camera_name,
        tenant_id,
        camera_address,
        camera_description,
        latitude: !facility_only ? params.select_camera.latitude : null,
        longitude: !facility_only ? params.select_camera.longitude : null,
        camera_type,
        is_open,
        facility_only,
      };
      const res = await camerasIdPutAPI(new_camera);
      params.onClose(res.data);
    }
  };

  const onCameraMovingReverse = () => {
    setIsCameraMoving(!is_camera_moving);
  };

  const onIsOpenReverse = () => {
    updateForm({ is_open: !form.is_open });
  };

  const onFacilityOnlyReverse = () => {
    updateForm({ facility_only: !form.facility_only });
  };

  return (
    <Drawer
      title='カメラ設定'
      placement='right'
      width={300}
      onClose={() => {
        params.onClose();
      }}
      open={params.open}
      mask={!is_camera_moving}
      maskClosable={false}
    >
      <CameraSelectForm
        value={camera_id}
        onChange={(value) => updateForm({ camera_id: value })}
        cameras={cameras_by_status}
        camera={params.select_camera}
      />
      <CameraNameTextAreaForm
        value={camera_name}
        onChange={(e) => updateForm({ camera_name: e.currentTarget.value })}
      />
      <TenantSelectForm value={tenant_id} onChange={(value) => updateForm({ tenant_id: value })} tenants={tenants} />
      <Form.Item
        label={<CameraPositionFormLabel onClick={onCameraMovingReverse} moving={is_camera_moving} />}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Space direction='vertical'>
          {is_camera_moving ? (
            <TitleTaggedComponent
              direction='vertical'
              title='住所'
              text_props={{
                underline: false,
              }}
            >
              <TextArea
                title='住所'
                placeholder='入力してください(必須)'
                value={camera_address}
                minLength={1}
                onChange={(e) => updateForm({ camera_address: e.currentTarget.value })}
                style={{ height: '30px', marginBottom: '5px' }}
              />
            </TitleTaggedComponent>
          ) : (
            <Typography.Text>住所：{camera_address}</Typography.Text>
          )}
          <Typography.Text>緯度：{params.new_camera_position?.lat ?? params.select_camera.latitude}</Typography.Text>
          <Typography.Text>経度：{params.new_camera_position?.lng ?? params.select_camera.longitude}</Typography.Text>
        </Space>
      </Form.Item>
      <CameraTypeSelectForm value={camera_type} onChange={(value) => updateForm({ camera_type: value })} />
      <CameraDescriptionTextAreaForm
        value={camera_description}
        onChange={(e) => updateForm({ camera_description: e.currentTarget.value })}
      />
      <CameraIsOpenSwitchForm checked={is_open} onClick={onIsOpenReverse} />
      <CameraFacilityOnlySwitchForm checked={facility_only} onClick={onFacilityOnlyReverse} />
      <CameraStatusForm />
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button shape='round' onClick={() => params.onClose()}>
          キャンセル
        </Button>
        <Button type='primary' shape='round' onClick={onSetCamera}>
          更新
        </Button>
      </Space>
    </Drawer>
  );
};

// -- finally export part --

export default DetailCameraDrawer;

/**
 * カメラ位置Formのlabel
 */
interface CameraPositionFormLabelProps {
  moving: boolean;
  onClick(): void;
}
function CameraPositionFormLabel({ moving, onClick }: CameraPositionFormLabelProps) {
  const onIconClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // 親からのイベントを無効化
    e.preventDefault();
  };
  return (
    <Space>
      <Typography.Text style={{ marginRight: 5 }}>カメラ位置</Typography.Text>
      <Tooltip title={moving ? 'カメラ位置変更解除' : 'カメラ位置変更'}>
        <Button
          type='primary'
          shape='circle'
          icon={moving ? <CloseOutlined onClick={onIconClick} /> : <EditOutlined onClick={onIconClick} />}
          size='small'
          onClick={onClick}
        />
      </Tooltip>
    </Space>
  );
}
