/*** エンティティ ***/

import { sendAxios } from './base/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
// import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import { ISO8601 } from '@shared/models/ISO8601';
import getClient from './base';

export type CameraType = 'pure' | 'turbo' | 'ultra';

export interface Camera {
  camera_id: number;
  camera_name: string;
  tenant_id: number;
  camera_address: string;
  camera_description: string;
  thumnail_path: string;
  latitude: number;
  longitude: number;
  application_version: string;
  camera_type: CameraType;
  app_param: string;
  line_info: string;
  is_open: boolean;
  facility_only: boolean;
  status: 'active' | 'preparing' | 'deleted';
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface CamerasMaps {
  camera_id: number;
  camera_name: string;
  tenant_id: number;
  camera_address: string;
  camera_description: string;
  thumnail_path: string;
  latitude: number;
  longitude: number;
  application_version: string;
  camera_type: CameraType;
  app_param: string;
  line_info: string;
  is_open: boolean;
  facility_only: boolean;
  status: 'active' | 'preparing' | 'deleted';
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  map_cameras: number[];
}

// const sample_camera1: Camera = {
//   camera_id: 1,
//   camera_name: 'Camera 1',
//   tenant_id: 1,
//   camera_address: '123 Main St, City',
//   camera_description: 'This is the first camera.',
//   thumnail_path: 's3://thumbnails/camera1.jpg',
//   latitude: 35.68055479159759,
//   longitude: 139.767971314588,
//   application_version: '1.0.0',
//   camera_type: 'pure',
//   app_param: 'param1=value1&param2=value2',
//   is_open: true,
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_camera2: Camera = {
//   camera_id: 2,
//   camera_name: 'Camera 2',
//   tenant_id: 2,
//   camera_address: '456 Elm St, Town',
//   camera_description: 'This is the second camera.',
//   thumnail_path: 's3://thumbnails/camera2.jpg',
//   latitude: 35.7055479159759,
//   longitude: 139.767971314588,
//   application_version: '2.0.0',
//   camera_type: 'turbo',
//   app_param: 'param3=value3&param4=value4',
//   is_open: false,
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_camera3: Camera = {
//   camera_id: 3,
//   camera_name: 'Camera 3',
//   tenant_id: 1,
//   camera_address: 'Tiyoda',
//   camera_description: 'This is the third camera.',
//   thumnail_path: 's3://thumbnails/camera3.jpg',
//   latitude: 35.70055479159759,
//   longitude: 139.657971314588,
//   application_version: '3.0.0',
//   camera_type: 'pure',
//   app_param: 'param1=value1&param2=value2',
//   is_open: true,
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_camera4: Camera = {
//   camera_id: 4,
//   camera_name: 'Camera 4',
//   tenant_id: 1,
//   camera_address: 'Tokyo',
//   camera_description: 'This is the forth camera.',
//   thumnail_path: 's3://thumbnails/camera4.jpg',
//   latitude: 35.7055479159759,
//   longitude: 139.757971314588,
//   application_version: '2.0.0',
//   camera_type: 'turbo',
//   app_param: 'param3=value3&param4=value4',
//   is_open: false,
//   status: 'active',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: null,
// };

// const sample_camera5: Camera = {
//   camera_id: 5,
//   camera_name: 'Camera 5',
//   tenant_id: 1,
//   camera_address: 'Tokyo',
//   camera_description: 'This is the forth camera.',
//   thumnail_path: 's3://thumbnails/camera5.jpg',
//   latitude: 35.678708,
//   longitude: 139.661973,
//   application_version: '2.0.0',
//   camera_type: 'turbo',
//   app_param: 'param3=value3&param4=value4',
//   is_open: false,
//   status: 'delete',
//   created_at: toISO8601(Date.now()),
//   updated_at: toISO8601(Date.now()),
//   deleted_at: toISO8601(20230905),
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCamera = (data: any): data is Camera => typeof data?.camera_id === 'number';
// // 緯度経度のサンプル
// const sample_latlng_list = [
//   { latitude: 35.68615, longitude: 139.76645 },
//   { latitude: 35.6418, longitude: 139.65135 },
//   { latitude: 35.7012, longitude: 139.83255 },
//   { latitude: 35.61635, longitude: 139.59095 },
//   { latitude: 35.7449, longitude: 139.9268 },
//   { latitude: 35.5867, longitude: 139.50275 },
//   { latitude: 35.6695, longitude: 139.77185 },
//   { latitude: 35.6615, longitude: 139.6723 },
//   { latitude: 35.6721, longitude: 139.6969 },
//   { latitude: 35.6646, longitude: 139.6942 },
//   { latitude: 35.6624, longitude: 139.71655 },
//   { latitude: 35.6599, longitude: 139.7032 },
//   { latitude: 35.6663, longitude: 139.7091 },
//   { latitude: 35.6572, longitude: 139.6887 },
//   { latitude: 35.6629, longitude: 139.7017 },
//   { latitude: 35.6557, longitude: 139.7189 },
//   { latitude: 35.6634, longitude: 139.7074 },
//   { latitude: 35.6571, longitude: 139.6892 },
//   { latitude: 35.6626, longitude: 139.701 },
//   { latitude: 35.6554, longitude: 139.7246 },
//   { latitude: 35.666, longitude: 139.7109 },
//   { latitude: 35.6548, longitude: 139.7076 },
//   { latitude: 35.6613, longitude: 139.70915 },
//   { latitude: 35.6556, longitude: 139.7203 },
//   { latitude: 35.6652, longitude: 139.7078 },
//   { latitude: 35.6587, longitude: 139.6971 },
//   { latitude: 35.6638, longitude: 139.70575 },
//   { latitude: 35.6563, longitude: 139.729 },
//   { latitude: 35.6671, longitude: 139.7117 },
//   { latitude: 35.6546, longitude: 139.7176 },
// ];

// const multipleCamera = (total: number): Camera[] => {
//   const base_sample_camera: Camera = {
//     camera_id: 0,
//     camera_name: 'Camera 0',
//     tenant_id: 0,
//     camera_address: '123 Main St, City',
//     camera_description: 'This is the first camera.',
//     latitude: 35.68055479159759,
//     longitude: 139.767971314588,
//     thumnail_path: 's3://thumbnails/camera1.jpg',
//     application_version: '1.0.0',
//     camera_type: 'pure',
//     app_param: 'param1=value1&param2=value2',
//     is_open: true,
//     status: 'active',
//     created_at: toISO8601(Date.now()),
//     updated_at: toISO8601(Date.now()),
//     deleted_at: null,
//   };
//   return new Array(total).fill(null).map((_, i) => {
//     const no = i + 1;
//     const camera_id = no;
//     const camera_name = `カメラ${no}`;
//     const camera_address = `東京都xxx区`;
//     const latitude = sample_latlng_list[i % sample_latlng_list.length].latitude;
//     const longitude = sample_latlng_list[i % sample_latlng_list.length].longitude;
//     return {
//       ...base_sample_camera,
//       camera_id,
//       camera_name,
//       camera_address,
//       latitude,
//       longitude,
//     };
//   });
// };

/*** [GET] /cameras ***/
export interface RequestCamerasGet extends CommonAPIRequestType {}

export const camerasGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `idadmin/cameras`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Camera[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_camera1, sample_camera2, sample_camera3, sample_camera4],
  });
};

/*** [GET] /cameras/map ***/
export interface RequestCamerasGet extends CommonAPIRequestType {}

export const camerasMapGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `idadmin/cameras/map`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<CamerasMaps[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_camera1, sample_camera2, sample_camera3, sample_camera4],
  });
};

/*** [GET] /cameras/status ***/
export interface RequestCamerasGet extends CommonAPIRequestType {
  status?: string;
}

export const camerasByStatusGetAPI = (params: RequestCamerasGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/cameras`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { status: params.status };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Camera[]>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: [sample_camera1, sample_camera2, sample_camera3, sample_camera4],
  });
};

/*** [GET] /cameras/{camera_id} ***/
export interface RequestCamerasIdGet extends CommonAPIRequestType {
  camera_id: number;
}

export const camerasIdGetAPI = (params: RequestCamerasIdGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `cameras/${params.camera_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // let mock_response;
  // switch (params.camera_id) {
  //   case 1:
  //     mock_response = sample_camera1;
  //     break;
  //   case 2:
  //     mock_response = sample_camera2;
  //     break;
  //   case 3:
  //     mock_response = sample_camera3;
  //     break;
  //   case 4:
  //     mock_response = sample_camera4;
  //     break;
  //   case 5:
  //     mock_response = sample_camera5;
  //     break;
  // }

  // 送信
  return sendAxios<Camera>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: mock_response,
  });
};

/*** [POST] /cameras ***/
export interface RequestCamerasIdPost extends CommonAPIRequestType {}

export const camerasIdPostAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `cameras`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Camera>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_camera1,
  });
};

/*** [PUT] /cameras/{camera_id} ***/
export interface RequestCamerasIdPut extends CommonAPIRequestType {
  camera_id: number;
  camera_name: string;
  tenant_id: number;
  camera_address: string;
  camera_description: string;
  latitude: number | null;
  longitude: number | null;
  camera_type: CameraType;
  line_info?: string;
  is_open: boolean;
  facility_only: boolean;
  status?: string;
}

export const camerasIdPutAPI = (params: RequestCamerasIdPut) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ header_options: { 'Content-Type': 'application/json' } });

  // パス・メソッドを定義
  const path = `idadmin/cameras/${params.camera_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = params;

  // 送信
  return sendAxios<Camera>({
    axios,
    path,
    query,
    form,
    method,
    // mock_response: sample_camera1,
  });
};
