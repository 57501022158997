import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { User, usersGetAPI } from 'api/users';

/**
 * テナント一覧を取得するcustom hooks
 */
export const useUsers = () => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const users_record: Record<string, User> = useMemo(() => {
    return users ? keyBy(users, (d) => String(d.user_id)) : {};
  }, [users]);
  const loadUsers = useCallback(async () => {
    setUsers(undefined);
    const res = await usersGetAPI();
    if (res.status === 200) {
      setUsers(res.data);
    } else {
      setUsers([]);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return useMemo(() => {
    return {
      users,
      users_record,
      setUsers,
      loadUsers,
    };
  }, [users, users_record, setUsers, loadUsers]);
};
