import Icon from '@ant-design/icons/lib/components/Icon';
import { FacilitySvg } from '@shared/components/Icons/FacilitySvg';
import { MapSvg } from '@shared/components/Icons/MapSvg';
import { SaveSvg } from '@shared/components/Icons/SaveSvg';
import { Button, Col, Drawer, DrawerProps, List, Row, Spin, Typography } from 'antd';
import { Camera } from 'api/cameras';
import { Map, mapsIdCameraDeleteAPI, mapsIdCameraPostAPI, mapsIdCmamerasGetAPI } from 'api/maps';
import { useMaps } from 'hooks/useMaps/useMaps';
import React from 'react';
import { useEffect, useState } from 'react';
import Sight_svg from 'assets/sight.svg';
import unSight_svg from 'assets/unsight.svg';
import Setting_svg from 'assets/setting.svg';

import '@shared/components/AntdList.css';
import MapCreateDialog from './MapCreateDialog';
import MapDetailDialog from './MapDetailDialog';
import { FacilityDisplaySwitchForm } from './commons';

export interface AreaListProps {
  onSelect(map: Map | null): void;
  open: boolean;
  onClose: () => void;
  selected_map: Map | null;
  area_selected_cameras: Camera[];
  onSelectCamera(camera: Camera[]): void;
}

export const AreaListDrawer = React.memo(
  ({ open, onClose, selected_map, onSelect, area_selected_cameras, onSelectCamera }: AreaListProps) => {
    const { maps, loadMaps } = useMaps(false);
    const [display_maps, setDisplayMaps] = useState<Map[]>([]);
    const [saved_selected_cameras, setSavedSelectedCameras] = useState<Camera[]>([]);
    const [loading, setLoading] = useState(true);
    const [setting_map, setSettingMap] = useState<Map | null>(null);
    const [is_modal_open, setIsModalOpen] = useState(false);
    const [facility_display, setFacilityDisplay] = useState(false);
    const [is_reload_maps, setIsReloadMaps] = useState(false);
    const onMapTitleClick = (map: Map) => {
      onSelect(map);
    };

    // マップ詳細のDrawerを閉じる関数。selected_camerasの有無でAPI呼出しを分岐
    const onCloseDetailArea = async (map_id: number, selected_cameras?: Camera[]) => {
      if (selected_cameras) {
        // saved_selected_camerasとselected_camerasのIDをset関数にする
        const saved_camera_ids = new Set(saved_selected_cameras.map((camera) => camera.camera_id));
        const selected_camera_ids = new Set(selected_cameras?.map((camera) => camera.camera_id));

        // saved_selected_camerasに含まれているカメラに対する処理
        for (const saved_camera of saved_selected_cameras) {
          if (!selected_camera_ids.has(saved_camera.camera_id)) {
            // selected_camerasに同様のデータが含まれていない場合、削除を実行
            await mapsIdCameraDeleteAPI({ map_id: map_id, camera_id: saved_camera.camera_id });
          }
          // saved_selected_camerasに存在するデータ(すでに登録されているデータ)の場合、何もしない
        }

        // selected_camerasに新しく追加されたカメラがあるかどうかをチェックし、あればPOSTを実行
        if (selected_cameras) {
          for (const selected_camera of selected_cameras) {
            if (!saved_camera_ids.has(selected_camera.camera_id)) {
              // saved_selected_camerasに含まれていないデータがselected_camerasにある場合、POSTを実行
              await mapsIdCameraPostAPI({ map_id: map_id, camera_id: selected_camera.camera_id });
            }
          }
        }
        setSavedSelectedCameras((prev) => [...prev, ...(selected_cameras || [])]);
      }
      onSelect(null);
      onSelectCamera([]);
    };

    const onClickMapSetting = (map: Map) => {
      setIsModalOpen(true);
      setSettingMap(map);
      onSelectCamera([]);
    };

    const onCloseModal = () => {
      setIsModalOpen(false);
      onSelect(null);
      onSelectCamera([]);
      setSettingMap(null);
    };

    const onDisplayReverse = () => {
      setFacilityDisplay(!facility_display);
    };

    const reloadMaps = () => {
      setIsReloadMaps(true);
    };

    useEffect(() => {
      loadMaps();
      // マップ選択された際に、すでに登録されているカメラを取得する
      if (selected_map) {
        // async/awaitを呼び出すためのfetchCameras定義
        const fetchCameras = async () => {
          // 登録済みカメラを取得するまでSpin表示
          setLoading(true);
          // マップIDからカメラを取得する
          const res = await mapsIdCmamerasGetAPI({ map_id: selected_map.map_id });
          if (res.status == 200) {
            onSelectCamera(res.data);
            setSavedSelectedCameras(res.data);
          }
          setLoading(false);
        };

        // マップ選択された際に、fetchCamerasを呼び出す。
        fetchCameras();
      }
    }, [selected_map]); /* eslint-disable-line */

    // -- reload function --
    useEffect(() => {
      if (is_reload_maps) {
        loadMaps();
        setIsReloadMaps(false);
      }
    }, [is_reload_maps]); /* eslint-disable-line */

    useEffect(() => {
      let filtered_maps = maps;
      if (!facility_display) {
        filtered_maps = filtered_maps?.filter((map) => map.is_facility == false);
      }
      setDisplayMaps(filtered_maps || []);
    }, [facility_display, maps]); /* eslint-disable-line */

    const drawer_props: DrawerProps = {
      placement: 'right',
      width: 300,
      maskClosable: true,
      title: !selected_map ? (
        ''
      ) : (
        <div style={{ display: 'flex' }}>
          <SightSvg is_open={selected_map.is_open} />
          <DynamicSvg type={selected_map.is_facility ? 'facility' : 'map'} />
          {selected_map.map_name}
        </div>
      ),
      onClose: !selected_map ? onClose : () => onCloseDetailArea(selected_map.map_id),
      open: selected_map ? true : open,
      mask: !selected_map ? true : false,
    };

    return (
      <Drawer {...drawer_props}>
        {!is_modal_open && selected_map ? (
          <SelectedCameraPanel
            selected_map={selected_map}
            area_selected_cameras={area_selected_cameras}
            saved_selected_cameras={saved_selected_cameras}
            onCloseDetailArea={onCloseDetailArea}
            loading={loading}
          />
        ) : (
          <>
            <UnselectedCameraPanel
              maps={display_maps}
              facility_display={facility_display}
              onMapTitleClick={onMapTitleClick}
              onClickMapSetting={onClickMapSetting}
              onChangeDisplay={onDisplayReverse}
              reloadMaps={reloadMaps}
            />
            {setting_map && (
              <MapDetailDialog
                map={setting_map}
                is_modal_open={is_modal_open}
                onClose={onCloseModal}
                reload={reloadMaps}
              />
            )}
          </>
        )}
      </Drawer>
    );
  },
);

interface SelectedCameraPanelProps {
  selected_map: Map;
  area_selected_cameras: Camera[];
  saved_selected_cameras: Camera[];
  onCloseDetailArea(map_id: number, selected_cameras?: Camera[]): void;
  loading?: boolean;
}
// マップ選択された場合のマップ詳細表示
function SelectedCameraPanel({
  selected_map,
  area_selected_cameras,
  saved_selected_cameras,
  onCloseDetailArea,
  loading,
}: SelectedCameraPanelProps) {
  // 重複を消してcamerasデータを結合
  const unique_cameras = [
    ...new globalThis.Map(
      [...area_selected_cameras, ...saved_selected_cameras].map((item) => [item.camera_id, item]),
    ).values(),
  ];

  if (loading) {
    return <Spin />;
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Typography.Text strong>カメラ紐づけ一覧</Typography.Text>
        </Col>
        <Col span={24}>
          <div
            style={{
              border: '1px solid black',
              padding: '5px',
              height: '100px',
              overflow: 'auto',
            }}
          >
            {unique_cameras.map((camera) => {
              const is_saved = saved_selected_cameras.some(
                (saved_camera) => saved_camera.camera_id === camera.camera_id,
              );
              const is_area_selected = area_selected_cameras.some(
                (area_selected_camera) => area_selected_camera.camera_id === camera.camera_id,
              );

              const span_style: React.CSSProperties = {
                color: is_saved && !is_area_selected ? 'grey' : 'black',
                marginLeft: !is_saved ? 40 : 10,
                height: is_saved ? 28 : undefined,
                textDecoration: is_saved && !is_area_selected ? 'line-through' : 'none',
              };

              return (
                <div key={camera.camera_id} style={{ display: 'flex' }}>
                  {is_saved && <SaveSvg checked={is_area_selected} />}
                  <span style={span_style}>{camera.camera_name}</span>
                </div>
              );
            })}
          </div>
        </Col>
        <Col span={24}>
          <Typography.Text>
            上記で追加したカメラがエリアか施設に表示されます。地図上のカメラをクリックすることで追加と削除ができます。
          </Typography.Text>
        </Col>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type='primary'
          shape='round'
          style={{ width: 80, marginRight: '10px' }}
          onClick={() => onCloseDetailArea(selected_map.map_id)}
        >
          戻る
        </Button>
        <Button
          type='primary'
          shape='round'
          style={{ width: 80 }}
          onClick={() => {
            onCloseDetailArea(selected_map.map_id, area_selected_cameras);
          }}
        >
          更新
        </Button>
      </div>
    </div>
  );
}

interface UnselectedCameraPanelProps {
  maps?: Map[];
  facility_display: boolean;
  onMapTitleClick(map: Map): void;
  onClickMapSetting(map: Map): void;
  onChangeDisplay(): void;
  reloadMaps(): void;
}
// マップ選択されていない場合のマップ一覧表示
function UnselectedCameraPanel({
  maps,
  facility_display,
  onMapTitleClick,
  onClickMapSetting,
  onChangeDisplay,
  reloadMaps,
}: UnselectedCameraPanelProps) {
  return maps ? (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <MapCreateDialog reload={reloadMaps} />
        <div style={{ marginLeft: '50px' }}>
          <FacilityDisplaySwitchForm checked={facility_display} onClick={onChangeDisplay} />
        </div>
      </div>
      <List
        itemLayout='horizontal'
        dataSource={maps}
        renderItem={(map) => {
          const meta = {
            type: map.is_facility ? 'facility' : 'map',
            title: map.map_name,
            description: map.map_description,
            is_open: map.is_open,
            onClick: () => onMapTitleClick(map),
          };

          return (
            <>
              <List.Item onClick={meta.onClick}>
                <List.Item.Meta
                  avatar={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon component={() => <SightSvg is_open={meta.is_open} />} />
                      <Icon component={() => <DynamicSvg type={meta.type} />} />
                    </div>
                  }
                  title={<Typography.Text>{meta.title}</Typography.Text>}
                  description={meta.description}
                />
                <img
                  src={Setting_svg}
                  alt='DELETEDのカメラ表示'
                  onClick={() => onClickMapSetting(map)}
                  style={{ height: '30px', width: '30px', top: 10 }}
                />
              </List.Item>
            </>
          );
        }}
      />
    </>
  ) : (
    <Spin />
  );
}

function DynamicSvg(props: { type: string }) {
  const { type } = props;
  switch (type) {
    case 'map':
      return <MapSvg style={{ width: 24, height: 24 }} />;
    case 'facility':
      return <FacilitySvg style={{ width: 24, height: 24 }} />;
    default:
      undefined;
  }
}

function SightSvg(props: { is_open: boolean }) {
  const { is_open } = props;
  if (is_open) {
    return <img src={Sight_svg} style={{ width: 24, height: 24 }} />;
  } else {
    return <img src={unSight_svg} style={{ width: 24, height: 24 }} />;
  }
}
