// -- http connection library --
import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { AxiosQuery } from '../models/Query';

// -- mock get funciton --

const toMock = <ResponseType>(
  target: AxiosInstance,
  path: string,
  query: AxiosQuery,
  method: string,
  response: ResponseType,
) => {
  // -- mock properation --
  const mock = new MockAdapter(target, { delayResponse: 500 });

  if (method === 'get') {
    mock.onGet(path, query).reply<ResponseType>(200, response);
  } else if (method === 'post') {
    mock.onPost(path).reply<ResponseType>(200, response);
  } else if (method === 'put') {
    mock.onPut(path).reply<ResponseType>(200, response);
  } else if (method === 'delete') {
    mock.onDelete(path, query).reply<ResponseType>(200, response);
  }
};

// -- finally export part --
export default toMock;
