import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Popover } from 'antd';
import { CamerasMaps, camerasIdPutAPI } from 'api/cameras';
import { useTenants } from 'hooks/useTenants/useTenants';
import { useMapsByTenantId } from 'hooks/useMaps/useMapsByTenant';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { mapsIdCameraDeleteAPI, mapsIdCameraPostAPI } from 'api/maps';
import { MapCheckBox, TenantSelectForm } from 'components/CameraDrawer/commons';
import { CameraListView } from 'components/ListView/CameraListView';

// デバイステーブルのtype
type DaialogCameraProps = {
  cameras: CamerasMaps[];
  reloadCameras(): void;
};

// -- main component --
/**
 * チェックしたカメラ詳細ダイアログです。
 */

const CheckCamerasDialog = (params: DaialogCameraProps) => {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [tenant_id, setTenantId] = useState<number>();
  const { tenants } = useTenants();
  const { maps, loadMaps } = useMapsByTenantId(tenant_id);
  const [checked_cameras_map_ids, setCheckedCamerasMapIds] = useState<number[]>([]);
  const [checked_maps, setCheckedMaps] = useState<number[]>([]);
  const [is_disabled, setIsDisabled] = useState<boolean>(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    // PromiseにてMapCameraTableのデータ削除/追加を並行で行う
    const promises = params.cameras.map(async (camera) => {
      //cameraに対して削除対象のmap_idをリスト化
      const maps_to_delete = camera.map_cameras.filter((map_id) => !checked_maps.includes(map_id));
      // Promise.allにて削除
      await Promise.all(
        maps_to_delete.map((map_id) => mapsIdCameraDeleteAPI({ map_id: map_id, camera_id: camera.camera_id })),
      );
      // cameraに対して作成対象のmap_idをリスト化
      const maps_to_post = checked_maps.filter((map_id) => !camera.map_cameras.includes(map_id));
      // Promise.allにて作成
      await Promise.all(
        maps_to_post.map((map_id) =>
          mapsIdCameraPostAPI({
            map_id: map_id,
            camera_id: camera.camera_id,
          }),
        ),
      );
      // テナントIDが更新された場合、CameraTableのデータを更新
      if (tenant_id && camera.tenant_id != tenant_id) {
        const new_camera = { ...camera, tenant_id: tenant_id };
        camerasIdPutAPI(new_camera);
      }
    });

    await Promise.all(promises);
    params.reloadCameras();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCheckboxChange = (map_id: number, e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckedMaps([...checked_maps, map_id]);
    } else {
      setCheckedMaps(checked_maps.filter((id) => id !== map_id));
    }
  };

  const content = (
    <div>
      <p>選択したカメラのテナントが一致していません。</p>
      <p>テナントが一致しているカメラのみ一括更新できます。</p>
    </div>
  );

  const allTenantsMatch = (cameras: CamerasMaps[]): boolean => {
    if (cameras.length === 0) return true;
    const first_tenant_id = cameras[0].tenant_id;
    const is_tenant_id_match = cameras.every((camera) => camera.tenant_id === first_tenant_id);
    setIsDisabled(!is_tenant_id_match);
    return is_tenant_id_match;
  };

  useEffect(() => {
    const unique_map_ids = new Set(params.cameras.flatMap((camera) => camera.map_cameras));
    setCheckedMaps(() => {
      const newArray = Array.from(unique_map_ids);
      return newArray; // 新しい配列を返す
    });

    setCheckedCamerasMapIds(() => {
      const newArray = Array.from(unique_map_ids);
      return newArray; // 新しい配列を返す
    });
    const all_match = allTenantsMatch(params.cameras);
    if (all_match && params.cameras.length > 0) {
      setTenantId(params.cameras[0].tenant_id);
    }
  }, [params.cameras]); /* eslint-disable-line */

  useEffect(() => {
    loadMaps(tenant_id);
    if (maps) {
      // 現在のテナントのmap_idを取得
      const current_map_ids = maps.map((map) => map.map_id);
      // unique_map_idsに存在するmap_idが現在のテナントのマップに含まれているかをチェック
      const is_map_included = current_map_ids.some((map_id) => checked_cameras_map_ids.includes(map_id));
      // もし含まれていない場合は、checked_mapsを空の配列にリセット
      if (!is_map_included) {
        setCheckedMaps([]);
      }
    }
  }, [tenant_id]); /* eslint-disable-line */

  return (
    <>
      {params.cameras.length > 0 && is_disabled ? (
        <Popover content={content}>
          <Button type='primary' onClick={showModal} disabled={is_disabled}>
            チェックしたカメラの一括更新
          </Button>
        </Popover>
      ) : (
        <Button type='primary' onClick={showModal} disabled={is_disabled}>
          チェックしたカメラの一括更新
        </Button>
      )}
      <Modal
        title={params.cameras.length > 0 ? params.cameras[0].camera_name : ''}
        open={is_modal_open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='更新'
        cancelText='キャンセル'
      >
        <Row gutter={16}>
          <Col span={12}>
            <CameraListView cameras={params.cameras} />
          </Col>
          <Col span={12}>
            <TenantSelectForm onChange={(value) => setTenantId(value)} value={tenant_id} tenants={tenants} />
            <MapCheckBox onChange={onCheckboxChange} value={checked_maps} maps={maps} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CheckCamerasDialog;
