import { useState } from 'react';
import { Button, Modal } from 'antd';
import { Map, mapsIdDeleteAPI, mapsIdPutAPI } from 'api/maps';
import { useTenants } from 'hooks/useTenants/useTenants';
import { useParentMaps } from 'hooks/useParentMaps/useParentMaps';
import {
  AreaIsOpenSwitchForm,
  AreaNameTextAreaForm,
  IsFacilitySwitchForm,
  MapDescriptionTextAreaForm,
  ParentMapForm,
  TenantSelectForm,
} from './commons';

// デバイステーブルのtype
type DaialogMapProps = {
  map: Map;
  is_modal_open: boolean;
  onClose(): void;
  reload(): void;
};

// -- main component --
/**
 * マップ詳細ダイアログです。
 */

const MapDetailDialog = (params: DaialogMapProps) => {
  const [map_name, setMapName] = useState<string>(params.map.map_name);
  const { tenants } = useTenants();
  const { parent_maps } = useParentMaps();
  const [tenant_id, setTenantId] = useState<number>(params.map.tenant_id);
  const [map_description, setMapDescription] = useState<string>(params.map.map_description);
  const [parent_map_id, setParentMapId] = useState<number | undefined>(params.map.parent_map_id || undefined);
  const [is_open, setIsOpen] = useState<boolean>(params.map.is_open);
  const [is_facility, setIsFacility] = useState<boolean>(params.map.is_facility);
  const [is_confirm_open, setIsConfirmOpen] = useState<boolean>(false);

  const handleOk = async () => {
    if (map_name && tenant_id) {
      const res = await mapsIdPutAPI({
        map_id: params.map.map_id,
        map_name: map_name,
        tenant_id: tenant_id,
        map_description: map_description,
        parent_map_id: parent_map_id,
        facility_name: parent_map_id ? map_name : undefined,
        is_facility: is_facility,
        is_open: is_open,
      });
      if (res.status == 200) {
        params.reload();
        params.onClose();
      }
    }
  };

  const handleCancel = () => {
    params.onClose();
  };

  const handleDeleteConfirm = async () => {
    setIsConfirmOpen(true);
  };

  const handleDelete = async () => {
    const res = await mapsIdDeleteAPI({ map_id: params.map.map_id });
    if (res.status == 200) params.onClose();
    setIsConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsConfirmOpen(false);
  };

  const handleTenantChange = (new_tenant_id: number) => {
    setTenantId(new_tenant_id);
  };

  const onClickIsOpen = () => {
    if (!is_open) setParentMapId(undefined);
    setIsOpen(!is_open);
  };

  return (
    <>
      <Modal
        title={`${params.map.map_name}の詳細`}
        open={params.is_modal_open}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            キャンセル
          </Button>,
          <Button key='submit' type='primary' onClick={handleOk}>
            更新
          </Button>,
          <Button key='delete' type='primary' onClick={handleDeleteConfirm} style={{ backgroundColor: '#ff4d4f' }}>
            削除
          </Button>,
        ]}
      >
        <p>エリアID：{params.map.map_id}</p>
        <AreaNameTextAreaForm value={map_name} onChange={(value) => setMapName(value.currentTarget.value)} />
        <TenantSelectForm value={tenant_id} onChange={handleTenantChange} tenants={tenants} />
        <MapDescriptionTextAreaForm
          value={map_description}
          onChange={(value) => setMapDescription(value.currentTarget.value)}
        />
        <AreaIsOpenSwitchForm checked={is_open} onClick={onClickIsOpen} />
        <IsFacilitySwitchForm checked={is_facility} onClick={setIsFacility} />
        {is_facility && <ParentMapForm value={parent_map_id} onChange={setParentMapId} parent_maps={parent_maps} />}
      </Modal>
      <Modal title='削除確認' open={is_confirm_open} onOk={handleDelete} onCancel={handleDeleteCancel}>
        {params.map.map_name} を削除しますか？
      </Modal>
    </>
  );
};

export default MapDetailDialog;
