import { Space, Input, Select } from 'antd';
import { useTenants } from 'hooks/useTenants/useTenants';
import { useEffect } from 'react';

export interface UserSearchSetting {
  user_name: string;
  mail_address: string;
  role: string;
  tenant_id: number | undefined;
}

export interface UserSearchSelectProps {
  form: UserSearchSetting;
  updateForm(form: UserSearchSetting): void;
}

export function SearchSelect({ form, updateForm }: UserSearchSelectProps) {
  const { tenants, loadTenants } = useTenants();

  useEffect(() => {
    loadTenants();
  }, [loadTenants]);

  const handleTenantChange = (value: number) => {
    updateForm({ ...form, tenant_id: value });
  };

  const handleRoleChange = (value: string) => {
    updateForm({ ...form, role: value });
  };

  const tenantOptions = [
    { label: '---選択してください---', value: '' },
    ...(tenants?.map((tenant) => ({
      label: tenant.tenant_name,
      value: tenant.tenant_id,
    })) || []),
  ];

  const roleOptions = [
    { label: '---選択してください---', value: '' },
    { label: 'admin', value: 'admin' },
    { label: 'general', value: 'general' },
  ];

  return (
    <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
      <label>ユーザー名</label>
      <Input
        value={form.user_name}
        onChange={(e) => updateForm({ ...form, user_name: e.target.value })}
        placeholder='ユーザー名'
      />
      <label>メールアドレス</label>
      <Input
        value={form.mail_address}
        onChange={(e) => updateForm({ ...form, mail_address: e.target.value })}
        placeholder='メールアドレス'
      />
      <label>権限</label>
      <Select
        value={form.role}
        onChange={handleRoleChange}
        options={roleOptions}
        placeholder='権限'
        style={{ width: '100%' }}
      />
      <label>テナント名</label>
      <Select
        value={form.tenant_id}
        onChange={handleTenantChange}
        options={tenantOptions}
        placeholder='テナント名'
        style={{ width: '100%' }}
      />
    </Space>
  );
}
