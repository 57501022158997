export interface CameraMarkerSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  gradient_option?: {
    left_percent: number;
    left_color?: string;
    right_color?: string;
  };
  selected?: boolean;
}

export function CameraMarkerSvg(props: CameraMarkerSvgProps) {
  const { color = 'black', gradient_option } = props;
  let _gradient_option = {
    left_color: color,
    right_color: color,
    left_percent: 50,
  };
  if (gradient_option) {
    _gradient_option = {
      left_color: 'blue',
      right_color: 'red',
      ...gradient_option,
    };
  }
  let camera_circle_color = _gradient_option.left_color;
  if (_gradient_option.left_percent < 50) {
    camera_circle_color = _gradient_option.right_color;
  }

  return (
    <svg {...props} width={200} height={200} xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 200 180`}>
      <defs>
        <linearGradient id='color-gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' style={{ stopColor: _gradient_option.left_color }} />
          <stop offset={`${_gradient_option.left_percent}%`} style={{ stopColor: _gradient_option.left_color }} />
          <stop offset={`${_gradient_option.left_percent}%`} style={{ stopColor: _gradient_option.right_color }} />
          <stop offset='100%' style={{ stopColor: _gradient_option.right_color }} />
        </linearGradient>
      </defs>
      {/* マーカーの形を描画 */}
      <path d='M100,0 C150,0 200,75 100,170 C0,75 50,0 100,0' fill='url(#color-gradient)' mask='url(#color-mask)' />

      {/* カメラのアイコンを描画  */}
      <g transform='translate(45, 15) scale(0.80)'>
        <rect x='20' y='40' width='100' height='60' rx='10' ry='10' fill='white' />
        <circle cx='70' cy='70' r='20' fill={`${camera_circle_color}`} />
        <circle cx='70' cy='70' r='10' fill='white' />
        <rect x='40' y='20' width='60' height='10' fill='white' />
      </g>
    </svg>
  );
}
