export type CameraStatus = 'active' | 'preparing' | 'facility_only' | 'deleted';

export const all_camera_statuses: CameraStatus[] = ['active', 'preparing', 'facility_only', 'deleted'];

// CameraStatus 型の値に該当するかどうかをチェックする関数
export const isCameraStatus = (value: string): value is CameraStatus => {
  return value === 'active' || value === 'preparing' || value === 'facility_only' || value === 'deleted';
};

// CameraStatus 型に変換する関数
export const changeCameraStatus = (params?: string) => {
  const camera_statuses = params?.split(',');
  const result: CameraStatus[] = camera_statuses ? camera_statuses.filter(isCameraStatus) : [];
  return result;
};

export const cameraStatusToJP = (status: CameraStatus) => {
  switch (status) {
    case 'active':
      return '地図連携済み';
    case 'preparing':
      return '未連携';
    case 'facility_only':
      return '施設のみ';
    case 'deleted':
      return '削除済み';
    default:
      return '';
  }
};
