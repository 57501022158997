import { Button, Drawer, Form, Space, Typography } from 'antd';
import { useEffect } from 'react';
import { useTenants } from 'hooks/useTenants/useTenants';
import TextArea from 'antd/es/input/TextArea';
import { Camera, CameraType, RequestCamerasIdPut, camerasIdPutAPI } from 'api/cameras';
import { LatLng } from 'leaflet';
import {
  CameraDescriptionTextAreaForm,
  CameraFacilityOnlySwitchForm,
  CameraIsOpenSwitchForm,
  CameraNameTextAreaForm,
  CameraSelectForm,
  CameraStatusForm,
  CameraTypeSelectForm,
  TenantSelectForm,
} from './commons';
import { useObj } from '@shared/hooks/useObj/useObj';
import { TitleTaggedComponent } from '@shared/components/TitleTaggedComponent';
import { useCamerasByStatus } from 'hooks/useCameras/useCamerasByStatus';

interface Form {
  tenant_id?: number;
  camera_id?: number;
  camera_name: string;
  camera_description: string;
  camera_address: string;
  camera_type: CameraType;
  is_open: boolean;
  facility_only: boolean;
}

interface AddCameraProps {
  open: boolean;
  new_camera_position: LatLng | null;
  onClose: (camera?: Camera) => void;
}

const AddCameraDrawer = (params: AddCameraProps) => {
  const { tenants, loadTenants } = useTenants();
  const { cameras_by_status, loadCamerasByStatus } = useCamerasByStatus('preparing');
  const { obj: form, updateObj: updateForm } = useObj<Form>({
    tenant_id: undefined,
    camera_id: undefined,
    camera_name: '',
    camera_description: '',
    camera_address: '',
    camera_type: 'pure',
    is_open: false,
    facility_only: false,
  });
  const { camera_id, camera_name, tenant_id, camera_address, camera_description, camera_type, is_open, facility_only } =
    form;

  useEffect(() => {
    loadTenants();
    loadCamerasByStatus();
  }, []); /* eslint-disable-line */

  const onSetCamera = async () => {
    if (camera_id && tenant_id) {
      const new_camera: RequestCamerasIdPut = {
        camera_id,
        camera_name,
        tenant_id,
        camera_address,
        camera_description,
        latitude: !facility_only && params.new_camera_position ? params.new_camera_position.lat : null,
        longitude: !facility_only && params.new_camera_position ? params.new_camera_position.lng : null,
        camera_type,
        is_open,
        facility_only,
      };
      const res = await camerasIdPutAPI(new_camera);
      if (res.status === 200) {
        params.onClose(res.data);
      }
    }
  };

  const onClickIsOpen = () => {
    updateForm({ is_open: !is_open });
  };

  const onClickFacilityOnly = () => {
    updateForm({ facility_only: !facility_only });
  };

  return (
    <Drawer
      title='新規カメラ追加'
      placement='right'
      width={300}
      onClose={() => params.onClose()}
      open={params.open}
      mask={false}
      maskClosable={false}
    >
      <CameraSelectForm
        value={camera_id}
        onChange={(value) => updateForm({ camera_id: value })}
        cameras={cameras_by_status}
      />
      <CameraNameTextAreaForm
        value={camera_name}
        onChange={(e) => updateForm({ camera_name: e.currentTarget.value })}
      />
      <TenantSelectForm value={tenant_id} onChange={(value) => updateForm({ tenant_id: value })} tenants={tenants} />

      <Form.Item required label='カメラ位置' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Space direction='vertical'>
          <TitleTaggedComponent
            direction='vertical'
            title='住所'
            text_props={{
              underline: false,
            }}
          >
            <TextArea
              title='住所'
              placeholder='入力してください(必須)'
              value={camera_address}
              minLength={1}
              onChange={(e) => updateForm({ camera_address: e.currentTarget.value })}
              style={{ height: '30px' }}
            />
          </TitleTaggedComponent>
          <Typography.Text>緯度：{params.new_camera_position?.lat ?? 0}</Typography.Text>
          <Typography.Text>経度：{params.new_camera_position?.lng ?? 0}</Typography.Text>
        </Space>
      </Form.Item>

      <CameraTypeSelectForm value={camera_type} onChange={(value) => updateForm({ camera_type: value })} />
      <CameraDescriptionTextAreaForm
        value={camera_description}
        onChange={(e) => updateForm({ camera_description: e.currentTarget.value })}
      />

      <CameraIsOpenSwitchForm checked={is_open} onClick={onClickIsOpen} />
      <CameraFacilityOnlySwitchForm checked={facility_only} onClick={onClickFacilityOnly} />
      <CameraStatusForm />
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button shape='round' onClick={() => params.onClose()}>
          キャンセル
        </Button>
        <Button type='primary' shape='round' onClick={onSetCamera}>
          登録
        </Button>
      </Space>
    </Drawer>
  );
};

// -- finally export part --

export default AddCameraDrawer;
