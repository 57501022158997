import { Form, Modal, Select, Typography } from 'antd';
import { Camera, CameraType, RequestCamerasIdPut, camerasIdPutAPI } from 'api/cameras';
import { Line } from '@shared/models/Line';
import { useTenants } from 'hooks/useTenants/useTenants';
import {
  AddressTextAreaForm,
  CameraDescriptionTextAreaForm,
  CameraFacilityOnlySwitchForm,
  CameraIsOpenSwitchForm,
  CameraNameTextAreaForm,
  CameraTypeSelectForm,
  LineInfoSettingForm,
  TenantSelectForm,
} from 'components/CameraDrawer/commons';
import { useObj } from '@shared/hooks/useObj/useObj';
import { useState } from 'react';

interface Form {
  tenant_id: number;
  camera_id: number;
  camera_name: string;
  camera_description: string;
  camera_address: string;
  camera_type: CameraType;
  line_info?: string;
  is_open: boolean;
  facility_only: boolean;
  status: string;
}
// デバイステーブルのtype
interface DaialogCameraProps {
  camera: Camera;
  is_modal_open: boolean;
  onClose(update: boolean): void;
}

// -- main component --
/**
 * カメラ詳細ダイアログです。
 */

const CameraDialog = (params: DaialogCameraProps) => {
  const setting_options = [
    { value: false, label: '設定しない' },
    { value: true, label: '設定する' },
  ];

  // 検知ライン情報の設定
  const default_line_info: Line[] = [
    { line_number: 0, line_name: 'line_name1', rl_name: 'RL', lr_name: 'LR', is_setting: false },
    { line_number: 1, line_name: 'line_name2', rl_name: 'RL', lr_name: 'LR', is_setting: false },
    { line_number: 2, line_name: 'line_name3', rl_name: 'RL', lr_name: 'LR', is_setting: false },
    { line_number: 3, line_name: 'line_name4', rl_name: 'RL', lr_name: 'LR', is_setting: false },
  ];
  const user_line_info = params.camera.line_info ? JSON.parse(params.camera.line_info) : default_line_info;
  const [line_info, setLineInfo] = useState<Line[]>(user_line_info);

  const { obj: form, updateObj: updateForm } = useObj<Form>({
    tenant_id: params.camera.tenant_id,
    camera_id: params.camera.camera_id,
    camera_name: params.camera.camera_name,
    camera_description: params.camera.camera_description,
    camera_address: params.camera.camera_address,
    camera_type: params.camera.camera_type,
    is_open: params.camera.is_open,
    facility_only: params.camera.facility_only,
    status: params.camera.status,
  });
  const {
    camera_id,
    camera_name,
    tenant_id,
    camera_address,
    camera_description,
    camera_type,
    is_open,
    facility_only,
    status,
  } = form;
  const { tenants } = useTenants();

  // 検知ラインの設定有無
  const onSettingChange = (value: boolean, index: number) => {
    // 新しいline_info配列を作成し、指定されたindexのis_settingを更新
    const new_line_info = [...line_info];
    new_line_info[index].is_setting = value;
    setLineInfo(new_line_info);
  };

  // 検知ライン名の変更
  const onLineNameChange = (value: string, index: number) => {
    // 新しいline_info配列を作成し、指定されたindexのline_nameを更新
    const new_line_info = [...line_info];
    new_line_info[index].line_name = value;
    setLineInfo(new_line_info);
  };

  // 検知ラインRL名の変更
  const onLineRlNameChange = (value: string, index: number) => {
    // 新しいline_info配列を作成し、指定されたindexのrl_nameを更新
    const new_line_info = [...line_info];
    new_line_info[index].rl_name = value;
    setLineInfo(new_line_info);
  };

  // 検知ラインLR名の変更
  const onLineLrNameChange = (value: string, index: number) => {
    // 新しいline_info配列を作成し、指定されたindexのlr_nameを更新
    const new_line_info = [...line_info];
    new_line_info[index].lr_name = value;
    setLineInfo(new_line_info);
  };

  // 更新処理
  const handleOk = async () => {
    const new_camera: RequestCamerasIdPut = {
      camera_id,
      camera_name,
      tenant_id,
      camera_address,
      camera_description,
      latitude: facility_only ? null : params.camera.latitude,
      longitude: facility_only ? null : params.camera.longitude,
      camera_type,
      line_info: JSON.stringify(line_info),
      is_open,
      facility_only,
      status:
        params.camera.latitude === null || params.camera.longitude === null || facility_only ? 'facility_only' : status,
    };
    const res = await camerasIdPutAPI(new_camera);
    if (res.status == 200) params.onClose(true);
  };

  const handleCancel = () => {
    params.onClose(false);
  };

  const onIsOpenReverse = () => {
    updateForm({ is_open: !form.is_open });
  };

  const onFacilityOnlyReverse = () => {
    updateForm({ facility_only: !form.facility_only });
  };

  return (
    <Modal
      title={params.camera.camera_name}
      open={params.is_modal_open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='更新'
      cancelText='キャンセル'
      width='70%'
      style={{ maxHeight: '80%', overflowY: 'auto' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '30%' }}>
          <p>カメラID：{params.camera.camera_id}</p>
          <CameraNameTextAreaForm
            value={camera_name}
            onChange={(value) => updateForm({ camera_name: value.currentTarget.value })}
          />
          <TenantSelectForm
            value={tenant_id}
            onChange={(value) => updateForm({ tenant_id: value })}
            tenants={tenants}
          />
          <AddressTextAreaForm
            value={camera_address}
            onChange={(value) => updateForm({ camera_address: value.currentTarget.value })}
          />
          <div>緯度：{params.camera.latitude}</div>
          <div style={{ marginBottom: '30px' }}>経度：{params.camera.longitude}</div>
          <CameraTypeSelectForm value={camera_type} onChange={(value) => updateForm({ camera_type: value })} />
          <CameraDescriptionTextAreaForm
            value={camera_description}
            onChange={(value) => updateForm({ camera_description: value.currentTarget.value })}
          />
          <CameraIsOpenSwitchForm checked={is_open} onClick={onIsOpenReverse} />
          <CameraFacilityOnlySwitchForm checked={facility_only} onClick={onFacilityOnlyReverse} />
          <p>アプリバージョン：{params.camera.application_version}</p>
        </div>

        <div style={{ width: '65%' }}>
          {/* [todo] サムネイル画像の表示 */}
          <img src='' alt='検知ライン設定後のサムネイル画像' />

          {line_info.map((info, index) => (
            <div key={index} style={{ margin: '30px 0' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Typography.Text style={{ whiteSpace: 'nowrap' }}>検知ライン{index}：</Typography.Text>
                <Select
                  style={{ width: '100%' }}
                  value={info.is_setting}
                  onChange={(value) => onSettingChange(value, index)}
                  options={setting_options}
                />
              </div>
              {info.is_setting && (
                <LineInfoSettingForm
                  line_info={info}
                  index={index}
                  onLineNameChange={onLineNameChange}
                  onLineRlNameChange={onLineRlNameChange}
                  onLineLrNameChange={onLineLrNameChange}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CameraDialog;
