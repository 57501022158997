import { CognitoUser2 } from '@shared/models/Cognito';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jotaiStorage: SyncStorage<any> = createJSONStorage(() => sessionStorage);

export const atomWithEncryptedStorage = <T>(key: string) => {
  const initial_str = window.sessionStorage.getItem(key);
  const initial: T = initial_str ? JSON.parse(initial_str) : null;

  return atomWithStorage<T>(key, initial, jotaiStorage);
};

export const user_atom = atomWithEncryptedStorage<CognitoUser2 | null>('user');

export const access_token_atom = atomWithEncryptedStorage<string | null>('access_token');
