import { Drawer, List, Spin, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
// import history from '@shared/browserHistory';
import { Camera, isCamera } from 'api/cameras';
import Icon from '@ant-design/icons';
import { CameraMarkerSvg, FacilitySvg, MapSvg } from '@shared/components/Icons';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useMemo } from 'react';
import { Map, isMap } from 'api/maps';
import { useCameras } from 'hooks/useCameras/useCameras';

export interface SearchEngineProps {
  onCameraSelect?(camera: Camera): void;
  onAreaSelect?(map: Map): void;
}

export function SearchEngine({ onCameraSelect, onAreaSelect }: SearchEngineProps) {
  const { cameras } = useCameras();
  const { cameras: searched_cameras, setCameras: setSearchedCameras } = useCameras();
  const { bool, onOpen, onClose } = useBoolean();
  const { maps } = useMaps(false);
  const { maps: searched_maps, setMaps: setSearchedMaps } = useMaps(false);

  const items: (Camera | Map)[] | undefined = useMemo(() => {
    if (!searched_cameras && !searched_maps) return undefined;
    const result: (Camera | Map)[] = [];
    searched_maps?.forEach((map) => {
      result.push(map);
    });
    searched_cameras?.forEach((camera) => {
      result.push(camera);
    });
    return result;
  }, [searched_maps, searched_cameras]);

  const onSearch = (value: string) => {
    // カメラの検索
    const filtered_cameras = cameras?.filter(
      (camera) => camera.camera_name.includes(value) || camera.camera_address.includes(value),
    );
    // マップの検索
    const filtered_maps = maps?.filter((map) => map.map_name?.includes(value) || map.map_description?.includes(value));
    setSearchedCameras(filtered_cameras);
    setSearchedMaps(filtered_maps);
    onOpen();
  };

  const onClickCameraRow = (camera: Camera) => {
    // history.push(`/urban/top?camera_id=${camera.camera_id}`);
    onCameraSelect?.(camera);
    onClose();
  };

  const onClickFacilityRow = (map: Map) => {
    // history.push(`/urban/top?map_id=${map.map_id}`);
    onAreaSelect?.(map);
    onClose();
  };

  return (
    <div>
      <Search placeholder='検索する' onSearch={(value) => onSearch(value)} style={{ width: 200 }} />
      <Drawer placement='left' width={300} onClose={onClose} open={bool}>
        {items ? (
          <List
            itemLayout='horizontal'
            dataSource={items}
            renderItem={(item) => {
              let meta: {
                type: string;
                title: string;
                description: string;
                onClick?(): void;
              } = {
                type: '',
                title: '',
                description: '',
                onClick: undefined,
              };
              if (isCamera(item)) {
                meta = {
                  type: 'camera',
                  title: item.camera_name,
                  description: item.camera_address,
                  onClick: () => onClickCameraRow(item),
                };
              } else if (isMap(item)) {
                meta = {
                  type: item.is_facility ? 'facility' : 'map',
                  title: item.map_name,
                  description: item.map_description,
                  onClick: () => onClickFacilityRow(item),
                };
              }

              return (
                <List.Item onClick={meta.onClick} className='antd-list-item'>
                  <List.Item.Meta
                    avatar={<Icon component={() => <DynamicSvg type={meta.type} />} />}
                    title={<Typography.Text>{meta.title}</Typography.Text>}
                    description={meta.description}
                  />
                </List.Item>
              );
            }}
          />
        ) : (
          <Spin />
        )}
      </Drawer>
    </div>
  );
}

function DynamicSvg(props: { type: string }) {
  const { type } = props;
  switch (type) {
    case 'map':
      return <MapSvg style={{ width: 48, height: 48 }} />;
    case 'camera':
      return <CameraMarkerSvg style={{ width: 48, height: 48 }} />;
    case 'facility':
      return <FacilitySvg style={{ width: 48, height: 48 }} />;
    default:
      return null;
  }
}
