import { Form, Select, SelectProps } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import { Tenant } from 'api/tenants';
import { Map } from 'api/maps';
import { User } from 'api/users';

export interface TenantTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function TenantNameTextAreaForm({ value, onChange }: TextAreaProps) {
  return (
    <Form.Item label='テナント名' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea
        title='テナント名'
        placeholder='入力してください(必須)'
        value={value}
        minLength={1}
        onChange={onChange}
        style={{ height: '30px' }}
      />
    </Form.Item>
  );
}

export interface TenantSelectFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
  tenants?: Tenant[];
}
export function TenantSelectForm({ value, onChange, tenants }: TenantSelectFormProps) {
  return (
    <Form.Item label='テナント' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select onChange={onChange} value={value} style={{ width: '100%' }}>
        {tenants &&
          tenants.map((tenant) => (
            <Select.Option key={tenant.tenant_id} value={tenant.tenant_id}>
              {tenant.tenant_name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export interface UserListViewProps {
  users?: User[];
}

export function UserListView({ users }: UserListViewProps) {
  return (
    <>
      <p>所属ユーザ</p>
      <div style={{ border: '1px solid black', padding: '5px', height: '100px', overflow: 'auto' }}>
        {users?.map((user) => {
          return (
            <div key={user.user_id} style={{ display: 'flex', marginBottom: '10px' }}>
              <span style={{ marginLeft: 5 }}>{user.user_name}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export interface MapListViewProps {
  maps?: Map[];
}
