import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import browserHistory from '@shared/browserHistory';
import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // 必要なlocaleファイルをインポート
dayjs.locale('ja');

import TopPage from 'pages/urban/top/TopPage';
import CamerasPage from 'pages/urban/cameras';
import TenantsPage from 'pages/urban/tenatns';
import UserPage from 'pages/urban/users';
import { LoginPage } from 'pages/urban/login/topPage';
import { DgIdAdminDocument } from 'components/DgIdAdminDocument';

function App() {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <Route
          path='/'
          render={({ location }) =>
            location.pathname !== '/login' && (
              <DgIdAdminDocument>
                <Switch>
                  <Route exact path='/urban/top' component={TopPage} />
                  <Route exact path='/urban/users' component={UserPage} />
                  <Route exact path='/urban/tenants' component={TenantsPage} />
                  <Route exact path='/urban/cameras' component={CamerasPage} />
                </Switch>
              </DgIdAdminDocument>
            )
          }
        />
      </Switch>
    </Router>
  );
}

export default App;
