import { Button, Form, Input } from 'antd';
import { hasNumber, hasLowerCase } from '../../functions';
import { CognitoUser2 } from '../../models/Cognito';
import Link from 'antd/es/typography/Link';
import { MessageInstance } from 'antd/es/message/interface';
import { completeNewPasswordArgs } from '../../functions/cognito';

type CompleteNewPasswordFieldType = {
  password: string;
  confirmation_password: string;
};

export interface CompleteNewPasswordPanelProps {
  user: CognitoUser2;
  onBack(): void;
  messageApi: MessageInstance;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cognitoCompleteNewPassword(args: completeNewPasswordArgs): Promise<any>;
}

export function CompleteNewPasswordPanel({
  user,
  onBack,
  messageApi,
  cognitoCompleteNewPassword,
}: CompleteNewPasswordPanelProps) {
  async function completeNewPassword(values: CompleteNewPasswordFieldType) {
    const { password } = values;

    try {
      const res = await cognitoCompleteNewPassword({ user, password });
      if (res.challengeName === 'MFA_REQUIRED' || res.challengeName === 'SMS_MFA') {
        console.log('MFAは現在対応していません');
        return;
      }
      messageApi.info(`パスワードの変更を完了しました。`);
      // パスワード変更処理完了
      onBack();
    } catch (error) {
      console.log('error complete new password', error);
    }
  }
  return (
    <Form onFinish={completeNewPassword} requiredMark={false} layout='vertical'>
      <Form.Item<CompleteNewPasswordFieldType>
        label='新しいパスワード'
        name='password'
        rules={[
          {
            required: true,
            message: 'パスワードを入力してください',
          },
          {
            min: 8,
            message: '8文字以上が必要です',
          },
          () => ({
            validator(_, value) {
              if (hasNumber(value) && hasLowerCase(value)) return Promise.resolve();
              return Promise.reject(new Error('少なくとも1つの英字と数字を含む必要があります。'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item<CompleteNewPasswordFieldType>
        label='確認用パスワード'
        name='confirmation_password'
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: '確認用パスワードを入力してください',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) return Promise.resolve();
              return Promise.reject(new Error('パスワードと確認用パスワードが一致しません'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Link onClick={onBack}>ログインページに戻る</Link>
      </Form.Item>
      <Form.Item>
        <Button type='primary' shape='round' htmlType='submit'>
          パスワード変更
        </Button>
      </Form.Item>
    </Form>
  );
}
