import UsersTable from './UserTable';
import { User } from 'api/users';
import { useState } from 'react';
import UserDetailDialog from './UserDetailDialog';

export default function UserPage() {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [is_reload_users, setReloadUsers] = useState(false);
  const [select_user, setSelectUser] = useState<User | undefined>();

  const onModalClose = (update: boolean) => {
    setIsModalOpen(false);
    setSelectUser(undefined);
    if (update) reloadUsers();
  };

  const reloadUsers = () => {
    setReloadUsers(!is_reload_users);
  };

  return (
    <div style={{ margin: 10 }}>
      <UsersTable
        onModalOpenChange={setIsModalOpen}
        selectUser={setSelectUser}
        is_reload_users={is_reload_users}
        reloadUsers={reloadUsers}
      />
      {select_user && <UserDetailDialog user={select_user} is_modal_open={is_modal_open} onClose={onModalClose} />}
    </div>
  );
}
