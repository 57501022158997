export type Query = Record<string, string | number | boolean | undefined>;
export type AxiosQuery = { params: Query };
/**
 *
 * @param query クエリパラメータ
 * @returns axios用のクエリパラメータ
 */
export const toAxiosQuery = (query: Query): AxiosQuery => {
  return { params: query };
};
