import { Table, Input, Spin } from 'antd';
import { Tenant } from 'api/tenants';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTenants } from 'hooks/useTenants/useTenants';
import TenantCreateDialog from './TenantCreateDialog';

// -- type declaration --

type TableTenantProps = {
  onModalOpenChange(is_modal_open: boolean): void;
  selectTenant(tenat: Tenant | undefined): void;
  is_reload_tenants: boolean;
  reloadTenants(): void;
};

// テナントテーブルのtype
type TableTenantType = {
  id: number;
  tenant_name: string;
  count_user: number;
  count_camera: number;
  count_map: number;
};

// -- main component --
/**
 * テナント一覧の表示テーブルです。
 */
const TenantsTable = memo(function TenantsTable(params: TableTenantProps) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableTenantType[] | undefined>(undefined);
  const [search_query, setSearchQuery] = useState('');
  const { tenants, loadTenants } = useTenants();

  const handleTenantNameClick = (tenant: Tenant) => {
    params.selectTenant(tenant);
    params.onModalOpenChange(true);
  };

  // テーブルの列の情報まとめたデータ

  const headers = [
    {
      title: 'テナントID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'テナント名',
      dataIndex: 'tenant_name',
      key: 'tenant_name',
      render: (text: string, record: TableTenantType) => (
        <a onClick={() => handleTenantNameClick({ tenant_id: record.id, tenant_name: record.tenant_name })}>{text}</a>
      ),
    },
    {
      title: 'ユーザ数',
      dataIndex: 'count_user',
      key: 'count_user',
    },
    {
      title: 'カメラ数',
      dataIndex: 'count_camera',
      key: 'count_camera',
    },
    {
      title: 'マップ数',
      key: 'count_map',
      dataIndex: 'count_map',
    },
  ];

  const loadTableBodies = useCallback(async () => {
    let filtered_tenants = tenants;

    // search_queryでフィルター
    if (search_query) {
      filtered_tenants = filtered_tenants?.filter((tenant) =>
        tenant.tenant_name.toLowerCase().includes(search_query.toLowerCase()),
      );
    }

    // テーブルデータを更新
    setTableBodies(
      filtered_tenants?.map((tenant) => ({
        id: tenant.tenant_id,
        tenant_name: tenant.tenant_name,
        count_user: tenant.users.length,
        count_camera: tenant.cameras.length,
        count_map: tenant.maps.length,
      })),
    );
  }, [tenants, search_query]);

  // -- reload function --
  useEffect(() => {
    if (params.is_reload_tenants) {
      loadTenants();
      params.reloadTenants();
    }
  }, [params.is_reload_tenants]); /* eslint-disable-line */

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [tenants, search_query]); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      {table_bodies ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Input.Search
              placeholder='テナント名で検索'
              onSearch={(value) => setSearchQuery(value)}
              style={{ width: '30%', marginRight: '16px' }}
            />
            <TenantCreateDialog reload={() => params.reloadTenants()} />
          </div>
          <Table columns={headers} dataSource={table_bodies.map((body, index) => ({ ...body, key: index }))} />
        </>
      ) : (
        <Spin />
      )}
    </>
  );
});

// -- finally export part --

export default TenantsTable;
