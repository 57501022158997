import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Camera, camerasGetAPI } from 'api/cameras';

/**
 * デバイス一覧を取得するcustom hooks
 */
export const useCameras = () => {
  const [cameras, setCameras] = useState<Camera[] | undefined>(undefined);
  const cameras_record: Record<string, Camera> = useMemo(() => {
    return cameras ? keyBy(cameras, (d) => String(d.camera_id)) : {};
  }, [cameras]);
  const loadCameras = useCallback(async () => {
    setCameras(undefined);
    const res = await camerasGetAPI();
    if (res.status === 200) {
      setCameras(res.data);
    }
  }, []);

  useEffect(() => {
    loadCameras();
  }, [loadCameras]);

  return useMemo(() => {
    return {
      cameras,
      cameras_record,
      setCameras,
      loadCameras,
    };
  }, [cameras, cameras_record, setCameras, loadCameras]);
};
