import { useState } from 'react';
import { Button, Modal, message } from 'antd';
import { tenantsPostAPI } from 'api/tenants';
import { TenantNameTextAreaForm } from 'components/TenantDialog/commons';

type TenantCreateDialogProps = {
  reload(): void;
};

// -- main component --
/**
 * テナント新規追加ダイアログです。
 */

const TenantCreateDialog = (params: TenantCreateDialogProps) => {
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [tenant_name, setTenantName] = useState<string>('');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (!tenant_name) {
      message.error('テナント名を設定してください');
    } else {
      await tenantsPostAPI({ tenant_name: tenant_name });
      params.reload();
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        新規テナント登録
      </Button>
      <Modal
        title='テナント新規追加'
        open={is_modal_open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='登録'
        cancelText='キャンセル'
      >
        <TenantNameTextAreaForm value={tenant_name} onChange={(e) => setTenantName(e.currentTarget.value)} />
      </Modal>
    </>
  );
};

export default TenantCreateDialog;
