import L from 'leaflet';
import { ReactNode } from 'react';
import { Marker, MarkerProps, Tooltip } from 'react-leaflet';

export interface DetailCameraMarkerLeafletIconProps extends MarkerProps {
  tooltip_content?: ReactNode;
  size: number;
  position: L.LatLngExpression;
  marker_props?: Partial<MarkerProps>;
  handleDragEnd(e: L.LeafletEvent): void;
  selected?: boolean;
  is_area: boolean;
  onClick?(): void;
}

export function DetailCameraMarkerLeafletIcon({
  tooltip_content,
  size,
  position,
  marker_props,
  handleDragEnd,
  selected,
  is_area,
  onClick,
}: DetailCameraMarkerLeafletIconProps) {
  const svg_markup = `
  <svg
  width="200px"
  height="200px"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 200 180"
  >
    <!-- マスクの定義 -->
    <defs>
      <mask id="color-mask">
        <!-- 白い部分はマスクで隠れず、黒い部分は隠れる -->
        <rect width="100%" height="100%" fill="white"/>
        <g transform="translate(45, 15) scale(0.80)" fill="black">
          <circle cx="200" cy="200" r="50"/>
        </g>
      </mask>
    </defs>
    <defs>
      <linearGradient id="color-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style="stop-color: 'black';" />
        <stop offset="100%" style="stop-color: 'black';" />
      </linearGradient>
    </defs>
    <!-- マーカーの形を描画 -->
    <path
      d="M100,0 C150,0 200,75 100,170 C0,75 50,0 100,0"
      fill="url(#color-gradient)"
      mask="url(#color-mask)"
    />
  <rect x='10' y='0' width='180' height='180' stroke="${selected ? 'red' : 'none'}" stroke-width='10' fill='none' />

    <!-- カメラのアイコンを描画 -->
    <g transform="translate(45, 15) scale(0.80)">
      <rect x="20" y="40" width="100" height="60" rx="10" ry="10" fill="white"/>
      <circle cx="70" cy="70" r="20" fill="black"/>
      <circle cx="70" cy="70" r="10" fill="white"/>
      <rect x="40" y="20" width="60" height="10" fill="white"/>
    </g>
  </svg>
  `;
  const iconUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg_markup)}`;
  const icon = L.icon({
    iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size],
  });

  return (
    <Marker
      {...marker_props}
      position={position}
      icon={icon}
      eventHandlers={{ dragend: handleDragEnd, click: onClick }}
      draggable={selected && !is_area}
    >
      <Tooltip>{tooltip_content}</Tooltip>
    </Marker>
  );
}
