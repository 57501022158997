import L from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';

export function PinLeafletIcon(props: { size: number; position: L.LatLngExpression; color?: string } & MarkerProps) {
  const { size, position } = props;
  const svg_markup = `
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="48" height="48" fill="url(#pattern0)"/>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlink:href="#image0_912_4457" transform="scale(0.0104167)"/>
      </pattern>
      <image id="image0_912_4457" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACyklEQVR4nO2cu4oUQRSGv0EQDFZEMTIy8BasYIPMRsYGXgIDMyMfwEjFQDcwMPQZTMxUzPQBNDTSRcRgI2VEWBcRwUtLQzfIojNdPd19TlX9H1TWzMz5/6pzaqoODUIIIYQQQgghxEDcBKZDfbiYzzpQAlvA2oJnxUDiN0MmGIpfygR78UuZME7BLVuMKh2pMA9AAXwOMEGFeQBkggMKrQR7CplgywHgZct6oMLcMyeB9wHiqzD3yGXgawfxZcKS7ALuAb+XEF8mdGQ/8KwH4WVCB44DGz2Lr5XQkgvAl4HElwlzmAA3esr3MiGQvcCTkYTXStjBsQHzvUxYwLn6BNNK/GxrwqTO978ciJ+dCSvAYweCZ2nCUeCNA6GjN6FLX85FYNuBwNFfb4b25Uwc5vtoV0JoX84K8MiBmEmYENqXcwR47UDEJExYD8yZ50c4zxlzVDUvir6cNeB2RPn+bYs/gtXkM2Ua8G/1pwNR246tels8Lz5z8bt0I5QRjOrE9dKC+O7gjJRMuLsgPnfid0lHpdPxvL5r/l98pgU39ZWwCRwkAWI04TtwmoSIzYSrJEgsJjwgYbyb8ArYQ+IUTk2oftNhMqFwZkJ1HHKWzCgcmXCLTCkcmPC0vgjKlsLQhHfAPmsBcjXhG3DKOnAvnAA+jGzAFeugcxb/vnXQOYv/AthtHXiu4n8EDlkHnqv4P4Az1oF7YBWYtRRt1uOlzjXrwGOb+bParD62qA+tA49V/IZlTNiou+6yZhnxlzFhu/7urAnN+as9XvRfJ3P6mPk70VtQDMVvkAmG4jfIBEPxG2TCP97T82kk8Rtkwl/i97XbCSVkd+S+nTCWmd9lJbhtpI1d/DYmSPyBxY+qfz+1mR9l//6Y4lfPj81UBXf8mZ88ntNO8kh8Y7zn/OSR+MZo5hujtGOMcr4xKrhCCCGEEEIIIYQQQhDOHywPviZMb7QlAAAAAElFTkSuQmCC"/>
    </defs>
  </svg>

  `;

  const iconUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg_markup)}`;
  const icon = L.icon({
    iconUrl,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
  });

  return <Marker {...props} position={position} icon={icon} />;
}
