// -- http connection library --
import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { jotaiStorage } from 'customJotai/atom';
import { onSuccess, onError } from './handlers';

// -- main axios base --

export const getClient = (args: {
  responseType?: AxiosRequestConfig['responseType'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  header_options?: CreateAxiosDefaults<any>['headers'];
  disabled_load?: boolean; // load処理を無効化できる
  disabled_error_message?: boolean; // エラーメッセージを無効化できる
  ended_load?: boolean; // load処理の終了動作を行う
}) => {
  const { responseType = 'json', header_options = {} } = args;
  const access_token = jotaiStorage.getItem('access_token', '');

  // -- authorized http client --
  const client = axios.create({
    headers: {
      Authorization: `Bearer ${access_token}`,
      ...header_options,
    },
    responseType: responseType,
  });

  // -- set interceptors --
  // client.interceptors.request.use((request) => onReady(request, disabled_load));
  client.interceptors.response.use(onSuccess, onError);
  // -- return part --
  return client;
};

export default getClient;
