import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja'; // 必要なlocaleファイルをインポート
dayjs.locale('ja');
import { hasAllAges, ageToJP, Age } from '../models/Age';
import { toStringBoolean } from '../models/StringBoolean';
import { Period } from '../models/Period';
import { Unit } from '../models/Unit';
import { formatHourDate, formatDayDate, formatWeekRangeDate, formatMonthDate } from './date';
import { Content, contentToJP } from '../models/Content';

/**
 * T[] -> Record<string, T>に変換する関数
 * @param arr: T[]
 * @returns arrをRecord<key: T>の形に変換したデータ
 */
export const keyBy = <T>(values: T[], toKey: (t: T) => string): Record<string, T> =>
  values.reduce((prev, cur, _1, _2, k = toKey(cur)) => ((prev[k] = cur), prev), {} as Record<string, T>);

export const joinMap = <T>(array_data: T[], mapFunction: (c: T) => string, join_text = '+') => {
  return array_data.map(mapFunction).join(join_text);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonToCsv = (json: any[]) => {
  const header = Object.keys(json[0]);
  const header_string = header.join(',');

  const replacer = (_key: string, value: string | number) => value ?? '';
  const row_items = json.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  const csv = [header_string, ...row_items].join('\r\n');
  return csv;
};

/**
 * 整形してformDataへ追加する(もしくは追加しない)関数
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addFormData = (args: { form: FormData; key: string; value: any }) => {
  const { form, key, value } = args;
  if (value === undefined || value === null) return;
  if (typeof value === 'string' || typeof value === 'number') {
    form.append(key, String(value));
  } else if (typeof value === 'boolean') {
    form.append(key, toStringBoolean(value));
  } else {
    form.append(key, value);
  }
  // else if (isBlob(value)) {
  //   form.append(key, value);
  // }
};

export const toAgeSettingValue = (ages: Age[]) => {
  let result = '年代：';
  if (hasAllAges(ages)) {
    result += '指定なし';
  } else {
    const add = ages.map(ageToJP).join('+');
    result += add;
  }
  return result;
};

export const toPeriodSettingValue = (period_setting: {
  date?: Dayjs;
  period: Period;
  unit: Unit;
  times?: [number, number];
}) => {
  const { date, period, unit, times } = period_setting;
  let result = '';
  if (period === 'today') {
    const today = dayjs();
    if (unit === 'hour') {
      result = formatHourDate(today);
    } else if (unit === 'day') {
      result = formatDayDate(today);
    } else if (unit === 'week') {
      result = formatWeekRangeDate(today);
    } else if (unit === 'month') {
      result = formatMonthDate(today);
    }
  } else if (period === 'period') {
    if (!date) return '';
    if (unit === 'hour') {
      if (!times) return '';
      result = formatHourDate(date, times[0], times[1]);
    } else if (unit === 'day') {
      result = formatDayDate(date);
    } else if (unit === 'week') {
      result = formatWeekRangeDate(date);
    } else if (unit === 'month') {
      result = formatMonthDate(date);
    }
  }
  return result;
};

export const toContentSettingValue = (contents: Content[]) => {
  if (contents.length === 0) {
    return '表示項目なし';
  }
  let result = '表示項目：';
  const add = contents.map(contentToJP).join('+');
  result += add;
  return result;
};

export const hasNumber = (input: string) => /[0-9]/.test(input);
export const hasLowerCase = (input: string) => /[a-z]/.test(input);
export const isValidEmail = (email: string) => {
  // メールアドレスの正規表現パターン
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // パターンに一致するかどうかを判定
  return emailPattern.test(email);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIdAdminCognitoUser = (attributes: Record<string, any>) => {
  return attributes && attributes['custom:role'] === 'idadmin';
};
