export interface FacilitySvgProps extends React.SVGProps<SVGSVGElement> {}
export function FacilitySvg(props: FacilitySvgProps) {
  return (
    <svg
      {...props}
      width='51'
      height='51'
      viewBox='0 0 51 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect width='51' height='51' fill='url(#pattern1)' />
      <defs>
        <pattern id='pattern1' patternContentUnits='objectBoundingBox' width='1' height='1'>
          <use xlinkHref='#image0_915_10970' transform='scale(0.0104167)' />
        </pattern>
        <image
          id='image0_915_10970'
          width='96'
          height='96'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEaklEQVR4nO1dO2hUQRQ9JibRCEJWIaAgoqaQbSTbWtqKWAQsxNLCQrGyClhapEkj2FpJUDGkSGFrFZBoQEixnUjUmI+K0eiGKwOzMCz7ee/Nf+YeuBA2y7ufs3vezNuZOwCDwWAwGAwGg8FghI8jAG4AWALwRdqSfE38j2EJDQDzADYBUA/bBfAUwBUAh5gJfZwCcA/A+z5F72XrAB4COMtElMMYgKsAFgD8q1D4TjsA8BrALQDHmAw9iSFNY4kyKDGkadlKlGmJIZaocCSGWKLCkRjKVaJCkxjKRaJikBhKbRQVs8RQzBI1BeAVgFYAhSDP1pK1mHJZ/K0AEqfAbMsVCS8CSJYCtecuCPgeQKIUqO24IMB3khS4MQFgArx/Com/Af4LQSxB/otBOd4DUn4UsRkDAcPyWYl4GPc3gKKRgZmueBg3A2A0BgJU1ADcBvA2gEJSSfsA4AGASY38vROgog7gkVzHQ4HaNoAnAC5byN8YdAMITaJaHRJjO39tmAyg5lGiekmMy/wrwVYAdQcSVURifOUfTADDhiWqrMT4zj+oAGoaElVVYgYhKwJUTMvfnAf5n5fvtYVsCWgjd/+lAxD6exPAuCf/VTAuYxaxR09A+/U9eWPVXdJBFgvQkKOkHwXyiY4A1ZoO/RdFUyOf6Ajo9r7jlvyjoC/KnYDfBSVKpwBtifmpEWeyBJBiH+Ws+JwB/6flfKBpIc5kCSBlYayu/wMHcSZLACXg3zjKBqA7uvBJQDMFAtSbn7qqLlQCBs1XoiRAZ4bpioCiM/aoCahyXVcEFEWyBDyWi1ttE7AjfVVFsgS095fNyOYb7f1lJggQQ9E38rcF3X1eSROg4gyAWej7n5XXMoVsCOiF3P17D4Ay9186gDsAJjz6r4oJGXv0BIjX/sgbq7jBjjj2XwZDcgIm9gL/6pNPdASotgFgzqH/opiTsZXNJzoCdAMlSwXQzSd6AhYBXCsgUToFGJE+FjXiTJYA6lg+eMmQf4GL8geezwbjTJYAUmzFgP8VB3EmSwAl4N84YisA5U5AvYv+hk7AtrwPNQz4N46qAajLzvdLXNcVAeoy9n4jsWgJUHGyxHVdEdAtpmQJ6IZPDgkQvqoiWQKGlOcwexYI2FeeRx3WiDNZAlScAHAXwCr0/a/Ka4lrmkAWBPRD7v6z3SI0XXCLlHVQRpvkahU2CVoHlbSW0nn2qAP/uhhVOv9W2SZrHd8qBKWuyQl1o3TdwEZx0c7GOhY0AlRtDcB9z60CJmUMa4ZyegYHEM1JvxoKmOTXXPxAct1Rs4xR6WvRcLMQ8c25AEcQjl5aaF28WWAUVZWA9ijGdEevlqzFeXhs3v3OcFJkqGGSzW4swZ0vYKt9fatjFDXo/bqjmKja1/c76W7ZkkSRgfeU/QAsx3pSn02JIssWnMTkcMLGbgwSk9oZMwexnBGTmkStpyYxumjwUYZhYIwP80xDotZZYsyiwQc6hzXRW5Tr+Tfk31FOlBgMBoOBbPEfqDRelGHAZCQAAAAASUVORK5CYII='
        />
      </defs>
    </svg>
  );
}
