import { useCallback, useMemo, useState } from 'react';

export function useBoolean(defaults?: boolean) {
  const [bool, setBool] = useState<boolean>(defaults ?? false);
  const onOpen = useCallback(() => setBool(true), []);
  const onClose = useCallback(() => setBool(false), []);
  const onReverse = useCallback(() => setBool((current) => !current), []);

  return useMemo(() => {
    return { bool, setBool, onOpen, onClose, onReverse };
  }, [bool, setBool, onOpen, onClose, onReverse]);
}
