import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser2 } from '../models/Cognito';
import { ClientMetaData } from '@aws-amplify/auth/lib-esm/types';

export interface completeNewPasswordArgs {
  user: CognitoUser2;
  password: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requiredAttributes?: any;
  clientMetadata?: ClientMetaData;
}
/**
 * CognitoのAPIを叩く際はこのクラスを介して行う
 */
export class Cognito {
  constructor(args: { region: string; userPoolId: string; userPoolWebClientId: string }) {
    const { region, userPoolId, userPoolWebClientId } = args;
    Amplify.configure({
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId,

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        // signUpVerificationMethod: 'code', // 'code' | 'link'
      },
    });
  }

  public async signUp({ username, password, email }: { username: string; password: string; email?: string }) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      return user;
    } catch (error) {
      if (error instanceof Error) {
        error.message = this.__getJpErrMessage(error, '新規登録');
        throw error;
      }
    }
  }

  // public async resendConfirmationCode({ username }) {
  //   try {
  //     await Auth.resendSignUp(username);
  //     console.log('code resent successfully');
  //   } catch (error) {
  //     error.message_jp = this.__getJpErrMessage(error, '検証コード再送');
  //     throw error;
  //   }
  // }

  // public async confirmSignUp({ username, code }) {
  //   try {
  //     await Auth.confirmSignUp(username, code);
  //   } catch (error) {
  //     error.message_jp = this.__getJpErrMessage(error, '仮登録認証');
  //     throw error;
  //   }
  // }

  // public listenToAutoSignInEvent() {
  //   Hub.listen('auth', ({ payload }) => {
  //     const { event } = payload;
  //     if (event === 'autoSignIn') {
  //       const user = payload.data;
  //       console.log({ user });
  //       // assign user
  //     } else if (event === 'autoSignIn_failure') {
  //       // redirect to sign in page
  //     }
  //   });
  // }

  public async signIn({ email, password }: { email: string; password: string }) {
    try {
      const user = await Auth.signIn(email, password);
      return user;
    } catch (error) {
      if (error instanceof Error) {
        error.message = this.__getJpErrMessage(error, 'ログイン');
        throw error;
      }
    }
  }

  public async isSignIn(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async signOut() {
    try {
      await Auth.signOut({ global: false });
    } catch (error) {
      console.log('error signing out: ', error);
      if (error instanceof Error) {
        error.message = this.__getJpErrMessage(error, 'ログアウト');
        throw error;
      }
    }
  }

  public async updateCurrentSession() {
    try {
      return await Auth.currentSession();
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        throw error;
      }
      throw error;
    }
  }

  public async getAccessToken() {
    try {
      const current_session = await Auth.currentSession();
      const accessToken: string = current_session.getAccessToken().getJwtToken();
      return accessToken;
    } catch (error) {
      console.log('Failed to get the access token', error);
      if (error instanceof Error) {
        error.message = this.__getJpErrMessage(error, 'ログイン情報取得');
        throw error;
      }
    }
  }

  public async completeNewPassword({ user, password, requiredAttributes, clientMetadata }: completeNewPasswordArgs) {
    try {
      const res = await Auth.completeNewPassword(user, password, requiredAttributes, clientMetadata);
      if (res.challengeName === 'MFA_REQUIRED' || res.challengeName === 'SMS_MFA') {
        console.log('MFAは現在対応していません');
        return;
      }
      return res;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    }
  }

  // public async changePassword({ oldPassword, newPassword }) {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const data = await Auth.changePassword(user, oldPassword, newPassword);
  //   } catch (error) {
  //     console.log('Failed to change the password', error);
  //     error.message_jp = this.__getJpErrMessage(error, 'パスワード変更');
  //     throw error;
  //   }
  // }

  // public async sendForgotPassword({ username }) {
  //   try {
  //     await Auth.forgotPassword(username);
  //   } catch (error) {
  //     console.log('Failed to send the ForgotPassword Mail', error);
  //     error.message_jp = this.__getJpErrMessage(error, 'リセットメール送信');
  //     throw error;
  //   }
  // }

  // public async validForgotPasswordCode({ username, code, new_password }) {
  //   try {
  //     await Auth.forgotPasswordSubmit(username, code, new_password);
  //   } catch (error) {
  //     console.log('Failed to valid the ForgotPassword Code', error);
  //     error.message_jp = this.__getJpErrMessage(error, 'コード検証');
  //     throw error;
  //   }
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private __getJpErrMessage(error: any, procName: string) {
    switch (error.code) {
      case 'UserNotConfirmedException':
        // ユーザのステータスが UNCONFIRMED の場合に起こる。
        // SignUp用のコードを再送し、ステータスを CONFIRMED にする必要がある。
        // 検証コードの再送は １．３節の ResendConfirmationCode() を参照。
        return 'メールアドレスの検証が完了していません。';
      case 'PasswordResetRequiredException':
        // Cognito コンソールでパスワードをリセット（ユーザープールにユーザをインポートする場合も含む）した場合に起こる。
        // パスワードをリセットする必要がある。
        // パスワードのリセットは 3.1節の SendForgotPasswordCode() 参照。
        return 'パスワードをリセットする必要があります。';
      case 'NotAuthorizedException':
        // 誤ったパスワードを入力した場合に起こる。
        // 注) パスワードを間違え続けた場合にも起こり、 error.message が 'Password attempts exceeded' になる。
        if (error.message == 'Password attempts exceeded') {
          return 'パスワードの試行回数が上限を超えたため、一時的に認証できなくなりました。';
        } else {
          return 'パスワードが誤っています。';
        }
      case 'UserNotFoundException':
        // PASSWORD_VERIFIER は通るものの username が Cognito ユーザープールに存在しない場合に起こる。
        return 'パスワードが誤っています。';
      case 'InvalidPasswordException':
        return 'パスワードは8文字以上の英数字を入力ください。大文字の英字、小文字の英字、数値を含める必要があります。';
      case 'InvalidParameterException':
        return 'パスワードを入力してください。';
      case 'LimitExceededException':
        return '試行回数が所定の回数を超えました。時間を置いて再度送信してください。';
      case 'CodeMismatchException':
        return '検証コードが誤っています。または有効期限が切れました。';
      case 'NetworkError':
        return 'インターネット接続に失敗しました。';
      default:
        // その他のエラー
        return procName + '処理でエラーが発生しました。(' + error.code + ':' + error.message + ')';
    }
  }
}
