import { Space, Input, Select } from 'antd';
import { useTenants } from 'hooks/useTenants/useTenants';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useEffect } from 'react';

export interface CameraSearchSetting {
  camera_name: string;
  tenant_id: number | undefined;
  map_id: number[];
}

export interface CameraSearchSelectProps {
  form: CameraSearchSetting;
  updateForm(form: CameraSearchSetting): void;
}

export function SearchSelect({ form, updateForm }: CameraSearchSelectProps) {
  const { tenants, loadTenants } = useTenants();
  const { maps, loadMaps } = useMaps(false);

  useEffect(() => {
    loadTenants();
    loadMaps();
  }, [loadTenants, loadMaps]);

  const handleTenantChange = (value: number) => {
    updateForm({ ...form, tenant_id: value });
  };

  const handleMapChange = (value: number[]) => {
    updateForm({ ...form, map_id: value });
  };

  const tenant_options = [
    { label: '---選択してください---', value: '' },
    ...(tenants?.map((tenant) => ({
      label: tenant.tenant_name,
      value: tenant.tenant_id,
    })) ?? []),
  ];

  const map_options = maps?.map((map) => ({
    label: map.map_name,
    value: map.map_id,
  }));

  return (
    <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
      <label>カメラ名</label>
      <Input
        value={form.camera_name}
        onChange={(e) => updateForm({ ...form, camera_name: e.target.value })}
        placeholder='カメラ名'
      />
      <label>テナント名</label>
      <Select
        value={form.tenant_id}
        onChange={handleTenantChange}
        options={tenant_options}
        placeholder='テナント名'
        style={{ width: '100%' }}
      />
      <label>エリア名</label>
      <Select
        mode='multiple'
        value={form.map_id}
        onChange={handleMapChange}
        options={map_options}
        placeholder='エリア名'
        style={{ width: '100%' }}
      />
    </Space>
  );
}
