import { hasNumber, hasLowerCase } from '../../functions';
import { Form, Input, Button } from 'antd';

export type LoginFieldType = {
  email: string;
  password: string;
};

export interface LoginPanelProps {
  login(values: LoginFieldType): void;
}
export function LoginPanel({ login }: LoginPanelProps) {
  return (
    <Form onFinish={login} requiredMark={false} layout='vertical'>
      <Form.Item<LoginFieldType>
        label='メールアドレス'
        name='email'
        rules={[{ required: true, message: 'ユーザー名を入力してください' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<LoginFieldType>
        label='パスワード'
        name='password'
        rules={[
          {
            required: true,
            message: 'パスワードを入力してください',
          },
          {
            min: 8,
            message: '8文字以上が必要です',
          },
          () => ({
            validator(_, value) {
              if (hasNumber(value) && hasLowerCase(value)) return Promise.resolve();
              return Promise.reject(new Error('少なくとも1つの英字と数字を含む必要があります。'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type='primary' shape='round' htmlType='submit'>
          ログイン
        </Button>
      </Form.Item>
    </Form>
  );
}
