import { Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { BaseMap } from './BaseMap';
import { AreaListDrawer } from 'components/AreaListDrawer';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useCamerasByStatus } from 'hooks/useCameras/useCamerasByStatus';
import { Camera } from 'api/cameras';
import { Map, mapsIdCmamerasGetAPI } from 'api/maps';
import AddCamera from 'components/CameraDrawer/AddCameraDrawer';
import { LatLng } from 'leaflet';
import DetailCameraDrawer from 'components/CameraDrawer/DetailCameraDrawer';
import { SearchEngine } from './SearchEngine';
import { useLocation } from 'react-router-dom';
import history from '@shared/browserHistory';

export default function TopPage() {
  const location = useLocation();
  const query_params = new URLSearchParams(location.search);
  const qp = query_params.get('qp');
  const { cameras, loadCameras } = useCameras();
  const { cameras_by_status, loadCamerasByStatus } = useCamerasByStatus('active');
  const [map_cameras, setMapCameras] = useState<Camera[]>(cameras ?? []);
  const [map_active_cameras, setMapActiveCameras] = useState<Camera[]>(cameras_by_status ?? []);
  const [selected_camera, setSelectedCamera] = useState<Camera | null>(null);
  const [area_selected_cameras, setAreaSelectedCameras] = useState<Camera[]>([]);
  const [new_camera_position, setNewCameraPosition] = useState<LatLng | null>(null);
  const [deleted_sight, setDeletedSight] = useState(true);
  const [selected_area, setSelectedArea] = useState<Map | null>(null);
  // カメラ追加の開閉状態
  const [is_add_camera_open, setIsAddCameraOpen] = useState(false);
  // カメラ詳細の開閉状態
  const [is_detail_camera_open, setIsDetailCameraOpen] = useState(false);
  // エリア一覧の開閉状態
  const [is_area_list_open, setIsAreaListOpen] = useState(false);

  // カメラ追加のドロワーの開閉状態をトグルする関数
  const onIsAddCameraOpenChange = (camera?: Camera) => {
    // 引数から取得できない場合はuseStateの値を反転した値を格納
    const _new = !is_add_camera_open;
    // 新規カメラの緯度経度、住所の初期化
    if (_new) {
      setNewCameraPosition(null);
    } else {
      history.push('/urban/top');
    }
    setSelectedCamera(null);
    setIsAddCameraOpen(_new);
    // カメラを設定した場合、表示カメラリストに新規カメラを追加
    if (camera) {
      map_cameras.push(camera);
    }
  };

  // カメラ詳細のドロワーの開閉状態をトグルする関数
  const handleDetailCameraChange = (camera?: Camera) => {
    // 反転
    const _new = !is_detail_camera_open;
    // 新規カメラの緯度経度、住所の初期化
    if (_new) {
      setNewCameraPosition(null);
    }
    setSelectedCamera(null);
    setIsDetailCameraOpen(_new);
    // カメラの変更をした場合、useMemoのカメラを表示する
    if (camera) {
      loadCamerasByStatus();
      loadCameras();
    }
  };

  // エリア一覧のドロワーの開閉状態をトグルする関数
  const handleAreaListChange = () => {
    const _new = !is_area_list_open;
    if (!_new) {
      history.push('/urban/top/');
    }
    setIsAreaListOpen(_new);
  };

  const onSelectCamera = (camera: Camera) => {
    setSelectedCamera(camera);
    setIsDetailCameraOpen(true);
  };

  const onSelectArea = async (map: Map | null) => {
    setSelectedArea(map);
    if (!map) return;
    setIsAreaListOpen(true);
    // [TODO]ここはget APIのqueryパラメータにidを複数指定できるようにして一括で取得したい
    const res = await mapsIdCmamerasGetAPI({ map_id: map.map_id });
    if (res.status === 200) {
      setAreaSelectedCameras(res.data);
    }
  };

  const onCameraClick = (camera: Camera) => {
    if (!is_area_list_open) {
      setSelectedCamera(camera);
      setIsDetailCameraOpen(true);
    } else {
      const camera_index = area_selected_cameras.findIndex(
        (selected_camera) => selected_camera.camera_id === camera.camera_id,
      );
      const new_area_selected_cameras = [...area_selected_cameras];

      if (camera_index !== -1) {
        // もしcameraが既に存在する場合
        new_area_selected_cameras.splice(camera_index, 1);
        setAreaSelectedCameras(new_area_selected_cameras);
      } else {
        // そうでない場合
        new_area_selected_cameras.push(camera);
        setAreaSelectedCameras(new_area_selected_cameras);
      }
    }
  };

  const onDeletedSightReverse = () => {
    setDeletedSight((current) => !current);
  };

  const onDragNewCameraPosition = (position: LatLng) => {
    setNewCameraPosition(position);
  };

  const onLoadCamera = useCallback(() => {
    let filter_camera: typeof cameras = [];
    let filter_camera_by_status: typeof cameras_by_status = [];
    if (selected_area) {
      filter_camera = cameras?.filter((camera) => camera.tenant_id === selected_area.tenant_id) ?? [];
      filter_camera_by_status =
        cameras_by_status?.filter((camera) => camera.tenant_id === selected_area.tenant_id) ?? [];
      setMapCameras(filter_camera);
      setMapActiveCameras(filter_camera_by_status);
    } else {
      setMapCameras(cameras || []);
      setMapActiveCameras(cameras_by_status || []);
    }
  }, [cameras_by_status, cameras, selected_area]);

  useEffect(() => {
    onLoadCamera();
  }, [onLoadCamera]);

  // query parameterからdrawerのopen属性を更新
  useEffect(() => {
    setIsAddCameraOpen(qp === 'add_camera');
    setIsAreaListOpen(qp === 'areas');
  }, [qp]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', zIndex: 1 }}>
      <BaseMap
        deleted_sight={deleted_sight}
        onDeletedSightReverse={onDeletedSightReverse}
        map_cameras={deleted_sight ? map_cameras : map_active_cameras}
        updateMapCameras={deleted_sight ? setMapCameras : setMapActiveCameras}
        onCameraMarkerClick={onCameraClick}
        selected_camera={selected_camera}
        add_camera={is_add_camera_open}
        is_area={is_area_list_open}
        area_selected_cameras={area_selected_cameras}
        setNewCameraPosition={onDragNewCameraPosition}
      />
      <div
        style={{
          width: '98%',
          height: 50,
          paddingLeft: 10,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Space style={{ zIndex: 2 }}>
          <SearchEngine onCameraSelect={onSelectCamera} onAreaSelect={onSelectArea} />
        </Space>
      </div>

      {is_add_camera_open && (
        <AddCamera
          open={is_add_camera_open}
          onClose={onIsAddCameraOpenChange}
          new_camera_position={new_camera_position}
        />
      )}

      {is_detail_camera_open && selected_camera && (
        <DetailCameraDrawer
          open={is_detail_camera_open}
          select_camera={selected_camera}
          new_camera_position={new_camera_position}
          onClose={handleDetailCameraChange}
        />
      )}
      {is_area_list_open && (
        <AreaListDrawer
          open={is_area_list_open}
          onClose={handleAreaListChange}
          selected_map={selected_area}
          onSelect={onSelectArea}
          area_selected_cameras={area_selected_cameras}
          onSelectCamera={setAreaSelectedCameras}
        />
      )}
    </div>
  );
}
