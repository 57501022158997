import { memo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useMessage from 'antd/es/message/useMessage';

import { user_atom } from 'customJotai/atom';
import { useAtomValue } from 'jotai';

export const AuthProvider = memo(function AuthProvider() {
  const [messageApi, context] = useMessage({ maxCount: 1 });
  const user = useAtomValue(user_atom);
  useEffect(() => {
    if (!user) {
      messageApi.error({
        type: 'error',
        content: 'ログインセッションが切れました',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return (
      <>
        {context}
        <Redirect to='/login' />
      </>
    );
  }
  return null;
});
