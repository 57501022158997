import { useState } from 'react';
import { Modal } from 'antd';
import { User, usersIdPutAPI } from 'api/users';
import { useTenants } from 'hooks/useTenants/useTenants';
import {
  MailAddressTextAreaForm,
  RoleSelectForm,
  TenantSelectForm,
  UserNameTextAreaForm,
} from 'components/UserDialog/commons';

// デバイステーブルのtype
type DaialogUserProps = {
  user: User;
  is_modal_open: boolean;
  onClose(put: boolean): void;
};

// -- main component --
/**
 * ユーザ詳細ダイアログです。
 */

const UserDetailDialog = (params: DaialogUserProps) => {
  const [user_name, setUserName] = useState<string>(params.user.user_name);
  const [mail_address, setMailAddress] = useState<string>(params.user.mail_address);
  const [role, setRole] = useState<string>(params.user.role);
  const { tenants } = useTenants();
  const [tenant_id, setTenantId] = useState<number>(params.user.tenant_id);

  const handleOk = async () => {
    await usersIdPutAPI({
      user_id: params.user.user_id,
      tenant_id,
      user_name,
      mail_address,
    });
    params.onClose(true);
  };

  const handleCancel = () => {
    params.onClose(false);
  };

  return (
    <Modal
      title={`${params.user.user_name}の詳細`}
      open={params.is_modal_open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='更新'
      cancelText='キャンセル'
    >
      <UserNameTextAreaForm value={user_name} onChange={(e) => setUserName(e.currentTarget.value)} />
      <MailAddressTextAreaForm value={mail_address} onChange={(e) => setMailAddress(e.currentTarget.value)} />
      <RoleSelectForm value={role} onChange={(value) => setRole(value)} />
      {role === 'admin' && (
        <TenantSelectForm value={tenant_id} onChange={(value) => setTenantId(value)} tenants={tenants} />
      )}
    </Modal>
  );
};

export default UserDetailDialog;
