import { Form, Select, SelectProps, Switch, SwitchProps } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import { Tenant } from 'api/tenants';
import { Map } from 'api/maps';

export interface AreaNameTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function AreaNameTextAreaForm({ value, onChange }: AreaNameTextAreaFormProps) {
  return (
    <Form.Item label='エリア名' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea
        title='エリア名'
        placeholder='入力してください(必須)'
        value={value}
        minLength={1}
        onChange={onChange}
        style={{ height: '30px' }}
      />
    </Form.Item>
  );
}

export interface TenantSelectFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
  tenants?: Tenant[];
}
export function TenantSelectForm({ value, onChange, tenants }: TenantSelectFormProps) {
  return (
    <Form.Item label='テナント' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select onChange={onChange} value={value} style={{ width: '100%' }}>
        {tenants &&
          tenants.map((tenant) => (
            <Select.Option key={tenant.tenant_id} value={tenant.tenant_id}>
              {tenant.tenant_name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export interface MapDescriptionTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function MapDescriptionTextAreaForm({ value, onChange }: TextAreaProps) {
  return (
    <Form.Item label='説明' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea
        title='説明'
        placeholder='入力してください'
        value={value}
        minLength={1}
        onChange={onChange}
        style={{ height: '60px' }}
      />
    </Form.Item>
  );
}

export interface AreaIsOpenSwitchFormProps {
  checked: SwitchProps['checked'];
  onClick: SwitchProps['onClick'];
}

export function AreaIsOpenSwitchForm({ checked, onClick }: AreaIsOpenSwitchFormProps) {
  return (
    <Form.Item label='全体公開' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Switch checked={checked} onClick={onClick} />
    </Form.Item>
  );
}

export interface IsFacilitySwitchFormProps {
  checked: SwitchProps['checked'];
  onClick: SwitchProps['onClick'];
}

export function IsFacilitySwitchForm({ checked, onClick }: IsFacilitySwitchFormProps) {
  return (
    <Form.Item label='エリア/施設' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Switch
        checked={checked}
        onClick={onClick}
        style={{
          marginBottom: '8px',
        }}
      />
    </Form.Item>
  );
}

export interface ParentMapFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
  parent_maps?: Map[];
}
export function ParentMapForm({ value, onChange, parent_maps }: ParentMapFormProps) {
  return (
    <Form.Item label='施設のあるエリア' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select onChange={onChange} value={value} style={{ width: '100%' }}>
        <Select.Option value={undefined}>なし</Select.Option>
        {parent_maps &&
          parent_maps.map((parent_map) => (
            <Select.Option key={parent_map.map_id} value={parent_map.map_id}>
              {parent_map.map_name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export interface FacilityDisplaySwitchFormProps {
  checked: SwitchProps['checked'];
  onClick: SwitchProps['onClick'];
}

export function FacilityDisplaySwitchForm({ checked, onClick }: FacilityDisplaySwitchFormProps) {
  return (
    <Form.Item label='施設表示' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Switch
        checked={checked}
        onClick={onClick}
        style={{
          marginBottom: '8px',
        }}
      />
    </Form.Item>
  );
}
