import { Space, ModalProps } from 'antd';
import { useObj } from '@shared/hooks/useObj/useObj';
import { ModalInputBase } from '@shared/components/ModalInputBase';
import { useEffect } from 'react';
import { SearchSelect, CameraSearchSetting } from './CameraSearchSelect';

export interface CameraSearchSettingModalProps extends ModalProps {
  onFinish?(form: CameraSearchSetting): void;
  initial_form: CameraSearchSetting;
}
export function CameraSearchSettingModal(props: CameraSearchSettingModalProps) {
  const { onFinish, initial_form } = props;
  const { obj: form, updateObj: updateForm } = useObj<CameraSearchSetting>(initial_form);

  const onCustomFinish = () => {
    onFinish?.(form);
  };

  useEffect(() => {
    updateForm(initial_form);
  }, [initial_form, updateForm]);

  return (
    <ModalInputBase {...props} onDisplay={onCustomFinish}>
      <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
        <SearchSelect form={form} updateForm={updateForm} />
      </Space>
    </ModalInputBase>
  );
}
