import { memo } from 'react';
import top from 'assets/top_icon.svg';
import user_list from 'assets/user_list_icon.svg';
import tenant_list from 'assets/tenant_list_icon.svg';
import area_list from 'assets/area_list_icon.svg';
import camera_list from 'assets/camera_list_icon.svg';
import add_camera from 'assets/add_camera_icon.svg';
import DgTopMenu, { TopMenuItem } from './TopMenuItem';

const top_menu_items: TopMenuItem[] = [
  {
    id: 'top',
    text: 'Top',
    img_src: top,
    url: '/urban/top',
  },
  {
    id: 'users',
    text: 'ユーザ一覧',
    img_src: user_list,
    url: '/urban/users',
  },
  {
    id: 'tenants',
    text: 'テナント一覧',
    img_src: tenant_list,
    url: '/urban/tenants',
  },
  {
    id: 'areas',
    text: 'エリア一覧',
    img_src: area_list,
    url: '/urban/top?qp=areas', // to
  },

  {
    id: 'cameras',
    text: 'カメラ一覧',
    img_src: camera_list,
    url: '/urban/cameras',
  },
  {
    id: 'add_camera',
    text: 'カメラ追加',
    img_src: add_camera,
    url: '/urban/top?qp=add_camera', // to
  },
];

export const DgAdminTopMenu = memo(function DgAdminTopMenu() {
  return <DgTopMenu default_id='top' top_menu_items={top_menu_items} is_idadmin_user={true} />;
});
