import { Camera, CamerasMaps } from 'api/cameras';

export interface CameraListViewProps {
  cameras?: CamerasMaps[] | Camera[];
}

export function CameraListView({ cameras }: CameraListViewProps) {
  return (
    <>
      <p>対象カメラ</p>
      <div style={{ border: '1px solid black', padding: '5px', height: '100px', overflow: 'auto' }}>
        {cameras?.map((camera) => (
          <div key={camera.camera_id} style={{ marginBottom: '5px' }}>
            <span>{camera.camera_name}</span>
          </div>
        ))}
      </div>
    </>
  );
}
