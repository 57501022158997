import Icon from '@ant-design/icons';
import { FacilitySvg, MapSvg } from '@shared/components/Icons';
import { Checkbox, CheckboxProps, Form, Select, SelectProps, Switch, SwitchProps, Typography } from 'antd';
import Input, { TextAreaProps } from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import { Camera } from 'api/cameras';
import { Tenant } from 'api/tenants';
import Sight_svg from 'assets/sight.svg';
import unSight_svg from 'assets/unsight.svg';
import { Map } from 'api/maps';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Line } from '@shared/models/Line';

export interface CameraSelectFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
  cameras?: Camera[];
  camera?: Camera;
}
export function CameraSelectForm({ value, onChange, cameras, camera }: CameraSelectFormProps) {
  return (
    <Form.Item label='カメラ' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select value={value} onChange={onChange} style={{ width: '100%' }}>
        {camera && (
          <Select.Option key={camera.camera_id} value={camera.camera_id}>
            {camera.camera_name}
          </Select.Option>
        )}
        {cameras &&
          cameras.map((camera) => (
            <Select.Option key={camera.camera_id} value={camera.camera_id}>
              {camera.camera_name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export interface CameraNameTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function CameraNameTextAreaForm({ value, onChange }: TextAreaProps) {
  return (
    <Form.Item label='カメラ表示名' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea
        title='カメラ表示名'
        placeholder='入力してください(必須)'
        value={value}
        minLength={1}
        onChange={onChange}
        style={{ height: '30px' }}
      />
    </Form.Item>
  );
}

export interface AddressTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function AddressTextAreaForm({ value, onChange }: TextAreaProps) {
  return (
    <Form.Item label='住所' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea
        title='住所'
        placeholder='入力してください(必須)'
        value={value}
        minLength={1}
        onChange={onChange}
        style={{ height: '30px' }}
      />
    </Form.Item>
  );
}

export interface TenantSelectFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
  tenants?: Tenant[];
}
export function TenantSelectForm({ value, onChange, tenants }: TenantSelectFormProps) {
  return (
    <Form.Item label='テナント' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select onChange={onChange} value={value} style={{ width: '100%' }}>
        {tenants &&
          tenants.map((tenant) => (
            <Select.Option key={tenant.tenant_id} value={tenant.tenant_id}>
              {tenant.tenant_name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

const camera_type_options = [
  {
    value: 'pure',
    name: 'pure',
  },
  {
    value: 'turbo',
    name: 'turbo',
  },
  {
    value: 'ultra',
    name: 'ultra',
  },
];

export interface CameraTypeSelectFormProps {
  onChange: SelectProps['onChange'];
  value: SelectProps['value'];
}
export function CameraTypeSelectForm({ value, onChange }: CameraTypeSelectFormProps) {
  return (
    <Form.Item label='カメラ種別' required labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Select onChange={onChange} value={value} style={{ width: '100%' }}>
        {camera_type_options.map((cto) => (
          <Select.Option key={cto.value} value={cto.value}>
            {cto.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export interface CameraDescriptionTextAreaFormProps {
  value: TextAreaProps['value'];
  onChange: TextAreaProps['onChange'];
}

export function CameraDescriptionTextAreaForm({ value, onChange }: CameraDescriptionTextAreaFormProps) {
  return (
    <Form.Item label='説明' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <TextArea title='説明' placeholder='入力してください' value={value} minLength={1} onChange={onChange} />
    </Form.Item>
  );
}

export interface CameraIsOpenSwitchFormProps {
  checked: SwitchProps['checked'];
  onClick: SwitchProps['onClick'];
}

export function CameraIsOpenSwitchForm({ checked, onClick }: CameraIsOpenSwitchFormProps) {
  return (
    <Form.Item label='全体公開' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Switch checked={checked} onClick={onClick} />
    </Form.Item>
  );
}

export interface CameraFacilityOnlySwitchFormProps {
  checked: SwitchProps['checked'];
  onClick: SwitchProps['onClick'];
}

export function CameraFacilityOnlySwitchForm({ checked, onClick }: CameraFacilityOnlySwitchFormProps) {
  return (
    <Form.Item label='facility viewのみの使用' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Switch checked={checked} onClick={onClick} />
    </Form.Item>
  );
}

export interface LineInfoSettingFormProps {
  line_info: Line;
  index: number;
  onLineNameChange: (value: string, index: number) => void;
  onLineRlNameChange: (value: string, index: number) => void;
  onLineLrNameChange: (value: string, index: number) => void;
}
export function LineInfoSettingForm({
  line_info,
  index,
  onLineNameChange,
  onLineRlNameChange,
  onLineLrNameChange,
}: LineInfoSettingFormProps) {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>検知ライン{index} 名：</Typography.Text>
        <Input
          placeholder='検知ライン名'
          value={line_info.line_name}
          onChange={(e) => onLineNameChange(e.target.value, index)}
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>検知ライン{index} RL 名：</Typography.Text>
        <Input
          placeholder='RL名'
          value={line_info.rl_name}
          onChange={(e) => onLineRlNameChange(e.target.value, index)}
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>検知ライン{index} LR 名：</Typography.Text>
        <Input
          placeholder='LR名'
          value={line_info.lr_name}
          onChange={(e) => onLineLrNameChange(e.target.value, index)}
        />
      </div>
    </>
  );
}

export interface MapCheckBoxProps {
  onChange(map_id: number, e: CheckboxChangeEvent): void;
  value: CheckboxProps['value'];
  maps?: Map[];
}

export function MapCheckBox({ maps, onChange, value }: MapCheckBoxProps) {
  return (
    <>
      <p>エリア一覧</p>
      <div style={{ border: '1px solid black', padding: '5px', height: '100px', overflow: 'auto' }}>
        {maps?.map((map) => (
          <div key={map.map_id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <Checkbox onChange={(e) => onChange(map.map_id, e)} checked={value.includes(map.map_id)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon component={() => <SightSvg is_open={map.is_open} />} />
                <Icon component={() => <DynamicSvg is_facility={map.is_facility} />} />
                {map.map_name}
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    </>
  );
}

function DynamicSvg(props: { is_facility: boolean }) {
  const { is_facility } = props;
  if (is_facility) {
    return <FacilitySvg style={{ width: 24, height: 24 }} />;
  } else {
    return <MapSvg style={{ width: 24, height: 24 }} />;
  }
}

function SightSvg(props: { is_open: boolean }) {
  const { is_open } = props;
  if (is_open) {
    return <img src={Sight_svg} style={{ width: 24, height: 24 }} />;
  } else {
    return <img src={unSight_svg} style={{ width: 24, height: 24 }} />;
  }
}

export function CameraStatusForm() {
  return (
    <Form.Item label='カメラ状態' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      xxxxx
    </Form.Item>
  );
}
