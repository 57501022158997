import history from '@shared/browserHistory';
import { Col, Row, Typography } from 'antd';
import { useSetAtom } from 'jotai';
import { access_token_atom, user_atom } from 'customJotai/atom';
import useMessage from 'antd/es/message/useMessage';
import { CognitoUser2 } from '@shared/models/Cognito';
import { useState } from 'react';
import { CompleteNewPasswordPanel } from '@shared/components/Login/completeNewPasswordPanel';
import { LoginFieldType, LoginPanel } from '@shared/components/Login/loginPanel';
import { isIdAdminCognitoUser } from '@shared/functions';
import { cognitoAuth } from 'utils/auth/cognito';

export function LoginPage() {
  const setUser = useSetAtom(user_atom);
  const setAccessToken = useSetAtom(access_token_atom);
  const [messageApi, context] = useMessage({ maxCount: 2 });
  const [sign_in_user, setSignInUser] = useState<CognitoUser2 | null>(null);

  const onBackToLoginPage = () => {
    setSignInUser(null);
  };

  const login = async (values: LoginFieldType) => {
    const { password, email } = values;
    const user_data: CognitoUser2 = await cognitoAuth.signIn({ email, password });
    if (user_data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setSignInUser(user_data);
      messageApi.info('初期パスワードの変更を行ってください');
    } else if (user_data.challengeName === 'MFA_REQUIRED' || user_data.challengeName === 'SMS_MFA') {
      // ２段階認証処理
      console.log('MFAは現在非対応です');
    } else {
      if (!isIdAdminCognitoUser(user_data.attributes)) {
        messageApi.error('権限エラー\n cognito userがidadmin権限を有していません');
        cognitoAuth.signOut();
        return;
      }
      cognitoAuth.getAccessToken().then(async (access_token) => {
        if (!access_token) return;
        setAccessToken(access_token);
        // ログイン完了
        setUser(user_data);
        history.push('/urban/top');
      });
    }
  };

  return (
    <>
      {context}
      <Row
        gutter={[24, 24]}
        style={{
          maxWidth: 300,
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translateY(-40%) translateX(-50%)',
        }}
      >
        <Col span={24}>
          <Typography.Title>IDEA DemoGrid</Typography.Title>
        </Col>
        <Col span={24}>
          {sign_in_user ? (
            <CompleteNewPasswordPanel
              user={sign_in_user}
              onBack={onBackToLoginPage}
              messageApi={messageApi}
              cognitoCompleteNewPassword={cognitoAuth.completeNewPassword}
            />
          ) : (
            <LoginPanel login={login} />
          )}
        </Col>
      </Row>
    </>
  );
}
