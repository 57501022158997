import { FacilitySvg, MapSvg } from '@shared/components/Icons';
import { Map } from 'api/maps';

export interface MapListViewProps {
  maps?: Map[];
}

export function MapListView({ maps }: MapListViewProps) {
  return (
    <>
      <p>エリア/施設一覧</p>
      <div style={{ border: '1px solid black', padding: '5px', height: '100px', overflow: 'auto' }}>
        {maps?.map((map) => {
          return (
            <div key={map.map_id} style={{ display: 'flex', marginBottom: '10px' }}>
              <DynamicSvg is_facility={map.is_facility} />
              <span style={{ marginLeft: 5 }}>{map.map_name}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

function DynamicSvg(props: { is_facility: boolean }) {
  const { is_facility } = props;
  if (is_facility) {
    return <FacilitySvg style={{ width: 24, height: 24 }} />;
  } else {
    return <MapSvg style={{ width: 24, height: 24 }} />;
  }
}
