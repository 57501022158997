import { ContextMenuItemClickEvent, LatLng, LeafletEvent } from 'leaflet';
import { MapContainer, ZoomControl, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// いい感じのcontextmenuをmapに拡張するためのプラグイン
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';

// カスタムマーカーアイコンの画像ファイルをインポート;
import { Col, Row, Space } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { PinLeafletIcon } from 'components/LeafletIcons';

import { Camera } from 'api/cameras';
import { AddCameraMarkerLeafletIcon } from 'components/LeafletIcons/AddCameraMarkerLeafletIcon';
import { DetailCameraMarkerLeafletIcon } from 'components/LeafletIcons/DetailCameraMarkerLeafletIcon';
import { SightIcon } from 'components/Icon/SightIcon';

interface ChangeMapCenterProps {
  position: LatLng;
}

function ChangeMapCenter({ position }: ChangeMapCenterProps) {
  const map = useMap();
  useEffect(() => {
    const mapBounds = map.getBounds();
    const mapZoom = map.getZoom();
    const left = mapBounds.getWest(); // 西側の経度
    const right = mapBounds.getEast(); // 西側の経度
    const center = mapBounds.getCenter();
    const width_degree = right - left;
    map.panTo(new LatLng(center.lat, position.lng + (width_degree * 0.5) / mapZoom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return null;
}

interface AddCameraProps {
  handleDragEnd(e: LeafletEvent): void;
}

function AddCamera(props: AddCameraProps) {
  const map = useMap();
  const [central_marker_position, setCentralMarkerPosition] = useState<LatLng>(sibuya_station);

  useEffect(() => {
    const currentCenter = map.getCenter();
    setCentralMarkerPosition(currentCenter);
  }, [map]);

  return (
    <AddCameraMarkerLeafletIcon
      size={56}
      position={central_marker_position}
      draggable={true}
      handleDragEnd={(e: LeafletEvent) => {
        props.handleDragEnd(e);
      }}
    />
  );
}

export interface BaseMapProps {
  onCameraMarkerClick(camera: Camera): void;
  map_cameras?: Camera[];
  selected_camera: Camera | null;
  add_camera: boolean;
  is_area: boolean;
  area_selected_cameras: Camera[];
  updateMapCameras(cameras: Camera[]): void;
  setNewCameraPosition(e: LatLng): void;
  deleted_sight: boolean;
  onDeletedSightReverse(): void;
}
const sibuya_station = new LatLng(35.6581, 139.7017);

export function BaseMap({
  map_cameras,
  selected_camera,
  updateMapCameras,
  onCameraMarkerClick,
  add_camera,
  is_area,
  area_selected_cameras,
  setNewCameraPosition,
  deleted_sight,
  onDeletedSightReverse,
}: BaseMapProps) {
  const [center_position, setCenterPosition] = useState<LatLng>(sibuya_station);
  const [home_position, setHomePosition] = useState<LatLng>(sibuya_station);
  // const [centralMarkerPosition, setCentralMarkerPosition] = useState<LatLng | null>(null);

  function contextmenu(ev: ContextMenuItemClickEvent) {
    const latlng = ev.latlng;
    setHomePosition(latlng);
  }
  function onCustomCameraMarkerClick(camera: Camera) {
    // is_areaがtrueの場合、直接onCameraMarkerClickを実行して終了
    if (is_area) {
      onCameraMarkerClick(camera);
      return;
    }
    // is_areaがfalseの場合の処理
    const position = new LatLng(camera.latitude, camera.longitude);
    setCenterPosition(position);
    onCameraMarkerClick(camera);
  }

  function onAddCameraDragEnd(e: LeafletEvent) {
    const latlng = e.target._latlng;
    // setCentralMarkerPosition(latlng);
    setNewCameraPosition(latlng);
  }
  function onDetailCameraDragEnd(e: LeafletEvent) {
    const { lat, lng } = e.target._latlng;
    // setCentralMarkerPosition(new LatLng(lat, lng));
    setNewCameraPosition(new LatLng(lat, lng));
    // 新しい latlng を使用してカメラデータを更新
    const updated_cameras = map_cameras?.map((camera) =>
      camera.camera_id === selected_camera?.camera_id
        ? {
            ...camera,
            latitude: lat,
            longitude: lng,
          }
        : camera,
    );

    // 状態を更新 (ここでは仮に setMapCameras が状態を更新する関数だと想定)
    updateMapCameras(updated_cameras as Camera[]);
  }

  return (
    <div
      style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1, top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <MapContainer
        center={center_position}
        zoom={13}
        style={{ width: '100%', height: '100%', zIndex: 0, position: 'relative' }}
        zoomControl={false}
        contextmenu={true}
        contextmenuItems={[
          {
            text: 'ピン移動',
            callback: contextmenu,
          },
        ]}
      >
        <ChangeMapCenter position={center_position} />
        <PinLeafletIcon position={home_position} size={40} />
        {map_cameras?.map((map_camera) => {
          const { latitude, longitude, camera_name } = map_camera;
          const descriptions_items: DescriptionsItemType[] = [];
          const position = new LatLng(latitude, longitude);

          let isSelected = false;
          if (is_area) {
            // 存在チェックはこのように行う
            isSelected = area_selected_cameras.some(
              (selectedCamera) => selectedCamera.camera_id === map_camera.camera_id,
            );
          } else {
            isSelected = selected_camera?.camera_id === map_camera.camera_id;
          }

          return (
            <Space key={map_camera.camera_id}>
              <DetailCameraMarkerLeafletIcon
                selected={isSelected}
                is_area={is_area}
                size={56}
                position={position}
                marker_props={{
                  zIndexOffset: 5,
                }}
                onClick={!add_camera ? () => onCustomCameraMarkerClick(map_camera) : () => {}}
                tooltip_content={
                  <Space direction='vertical'>
                    <Title level={5} style={{ margin: 0 }}>
                      {camera_name}
                    </Title>
                    <Row gutter={[16, 16]} style={{ width: 200 }}>
                      {descriptions_items.map((item) => {
                        return (
                          <Col key={item.key} span={24}>
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                  </Space>
                }
                handleDragEnd={
                  !is_area
                    ? (e: LeafletEvent) => {
                        onDetailCameraDragEnd(e);
                      }
                    : () => {}
                }
              />
            </Space>
          );
        })}
        {add_camera && <AddCamera handleDragEnd={onAddCameraDragEnd} />}
        <ZoomControl position='bottomleft' />
        <TileLayer
          attribution='© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
      </MapContainer>
      <div
        style={{
          bottom: '70px',
          left: '13px',
          width: '40px',
          height: '35px',
          position: 'absolute',
          display: 'flex',
          zIndex: 2,
          justifyContent: 'left',
        }}
      >
        <SightIcon deleted_sight={deleted_sight} onDeletedSight={onDeletedSightReverse} />
      </div>
    </div>
  );
}
