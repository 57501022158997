import Sight_svg from 'assets/sight.svg';
import unSight_svg from 'assets/unsight.svg';

interface SightIconProps {
  deleted_sight: boolean;
  onDeletedSight(): void;
}

export function SightIcon(props: SightIconProps) {
  if (props.deleted_sight) {
    return (
      <img
        src={Sight_svg}
        alt='DELETEDのカメラ表示'
        onClick={props.onDeletedSight}
        style={{ height: '30px', width: '30px', top: 10 }}
      />
    );
  } else {
    return (
      <img
        src={unSight_svg}
        alt='DELETEDのカメラ非表示'
        onClick={props.onDeletedSight}
        style={{ height: '30px', width: '30px' }}
      />
    );
  }
}
